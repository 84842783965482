import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import RoutesConstants from '../shared-components/Routes/routes.constants';
import { useStore } from './StoreProvider';

interface IProps {
  children: React.ReactNode
}

const AuthProvider = (props: IProps) => {
  const navigate = useNavigate();
  const { preferencesStore, authStore } = useStore();
  const { getToken, isAuthenticated } = authStore;

  useEffect(() => {
    if (getToken) {
      preferencesStore.UpdateLanguage();
    }
    // else if (!getToken || !isAuthenticated) {
    //   navigate(RoutesConstants.Login);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {(getToken || isAuthenticated) ? props.children : <Navigate to={`${RoutesConstants.Login}?continue=${encodeURIComponent(window.location.href)}`} />}
    </React.Fragment>
  )
}

export default AuthProvider;