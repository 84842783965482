import React, { useEffect, useState } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { ICategoryInfo, IResultInfo } from '../../../models/response/ITestInterpretation';
import { ITestInfo } from '../../../models/ITestInfo';
import ITestColorMapping from '../../../models/ITestColorMapping';

interface IProps {
    dataPoint: ICategoryInfo;
    allTestInfo: ITestInfo[];
    hiddenTestId: number[];
}

const ScatterGraph = ({dataPoint, allTestInfo, hiddenTestId}: IProps) => {
    const [hoveredOverTestId, setHoveredOverTestId] = useState<number>(-2);
    const subCategory1 = (dataPoint.SubCategoryList[0]);
    const subCategory2 = dataPoint.SubCategoryList[1];
    subCategory2.Results = (dataPoint.SubCategoryList[1]).Results.sort((a, b) => a.Result - b.Result);
    const testArray: Number[] = [];

    const opacityIfHovered = (testId: number) => {
        // If any test hovered AND testId is not equal to the hoveredtest's testId 
        if (hoveredOverTestId !== -2 && hoveredOverTestId !== testId)
            return "opacity-25";
        else
            return "";
    }
    return (
        <>
            { subCategory1 && subCategory2 &&
                <div className='position-relative d-flex justify-content-between align-items-center w-100' style={{height: '150px'}}>
                    <div className='text-uppercase text-dark scatter_text w-50' style={{transform: `translateY(-30px)`}}><FormattedMessage id={subCategory1.SubCategoryKey}/></div>

                        {/* Threshold OR Circle */}
                        <div className='position-absolute d-flex justify-content-center w-100'>
                            <div className='border-2 rounded-circle scatter_circle' style={{ borderColor: "red", borderStyle: 'solid', width: '60px', height: '60px' }}></div>
                        </div>

                        {/* User Icon and Tooltip*/}
                        { allTestInfo.map((test: ITestInfo, index: number) => {
                            const res1 = subCategory1.Results.find(x => x.TestId == test.TestId)?.Result;
                            const res2 = subCategory2.Results.find(x => x.TestId == test.TestId)?.Result;
                            if (res1 && res2) {
                                const rightPercentage = res1;
                                const leftPercentage = res2;
                                const temp = (-rightPercentage * 0.5) + (leftPercentage * 1.5);
                                const finalPercentageFromLeft = (temp < 100 && temp > 0) ? temp : (temp <= 0) ? 0 : 100;
                                
                                // Get the count of same percentage from the temp array, if any. We'll use this to apply
                                // the top css property so that the overlapped data, can be easily shown. 
                                const count = testArray.reduce((acc: number, num) => {
                                    return num === finalPercentageFromLeft ? acc + 1 : acc;
                                }, 0);

                                // Push the result into a dummy array.
                                testArray.push(finalPercentageFromLeft);
                                
                                return <>
                                <div className={`scatter_user_icon position-absolute d-flex justify-content-center align-items-center h-100 ${opacityIfHovered(test.TestId)} ${hiddenTestId.includes(test.TestId) ? 'hide' : ''}`} 
                                    style={{left: (`calc(${finalPercentageFromLeft}% ${finalPercentageFromLeft <= 0 ? '+' : '-'} 10px)`), top: (`${12 * count}px`), width: "20px"}}>
                                    <OverlayTrigger
                                        key='top'
                                        placement='top' 
                                        onEnter={() => setHoveredOverTestId(test.TestId)}
                                        onExit={() => setHoveredOverTestId(-2)}
                                        overlay={
                                            <Tooltip className='' id='tooltip-top'>{`${test.FirstName ?? ""} ${test.LastName ?? ""} \n ${test.UserEmail}`}</Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon color={test.Color} icon={faUser} className='user_image' size="lg" style={{ zIndex: '1'}}
                                            onMouseOver={() => setHoveredOverTestId(test.TestId)}
                                            onMouseLeave={() => setHoveredOverTestId(-2)}
                                        />
                                    </OverlayTrigger>

                                    {hoveredOverTestId === test.TestId && (
                                        <>
                                            <div className='position-absolute text-dark' style={{ transform: 'translate(-120%, -50%)', zIndex: '2'}}>{res1}</div>
                                            <div className='position-absolute text-dark' style={{ transform: 'translate(120%, -50%)', zIndex: '2'}}>{res2}</div>
                                        </>
                                    )}
                                </div>
                                </>
                            }
                            })
                        }

                    <div className='text-uppercase text-dark scatter_text text-end w-50' style={{transform: `translateY(-30px)`}}><FormattedMessage id={subCategory2.SubCategoryKey}/></div>
                </div>
            }
        </>
    );
}

export default ScatterGraph;



