import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useStore } from "../../../contexts/StoreProvider";
import { Link, Navigate, useNavigate } from "react-router-dom";
import RoutesConstants from "../../../shared-components/Routes/routes.constants";
import DeletePopupHOC from "../../agent-dashboard/hoc/DeletePopupHOC";
import DeletePopup from "../../agent-dashboard/components/Delete/DeletePopup";
import { FormattedMessage } from "react-intl";
import { ITestInfo } from "../../../models/ITestInfo";
import { formatMessage } from "../../../translations/format-message";
import Pagination from "../../../shared-components/Grid/Pagination";
import { pageLimitOptions } from "../../../constants/options.constant";

const TestView = (props: any) => {
  const { setSelectedTestsForComparison, selectedTestsForComparison} = props
  const navigate = useNavigate();
  const { authStore, testStore } = useStore();
  const { getAllTest, testDataList, allTest, deleteTest, deleteTestState, resetDeleteTest,testPaginationState,updateTestPageNumber, updateTestPageSize,resetTestPagingState } = authStore;
  const { resetTestInterpretation } = testStore;
  const [deleteTestId, setDeleteTestId] = useState<number>(-1);
  const [deleteTestName, setDeleteTestName] = useState<string>("");
  const handleViewResult = (testId: number, userEmail: string) => {
    navigate(`${RoutesConstants.TestResult}/${testId}`);
  }

  const callGetAllTest = () => {
    getAllTest(testPaginationState.PagingDetails.PageNo,testPaginationState.PagingDetails.PageSize);

}
  const handleDeleteTest = (testId: number) => {
    deleteTest(testId);
  }

  const handleDeleteClick = (eventId: number, eventName: string) => {
    setDeleteTestId(eventId);
    setDeleteTestName(eventName);
    props.deletePopupToggleHandler();
  };

  useEffect(() => {
    if (deleteTestState.success) {
      callGetAllTest();
      resetDeleteTest();
      props.deletePopupToggleHandler();
    }
  }, [deleteTestState.success]);

  /*initial-load */
  useEffect(() => {
    //getAllTest(testPaginationState.PagingDetails.PageNo,testPaginationState.PagingDetails.PageSize);
    // resetTestInterpretation();
  }, []);

  useEffect(() => {
    if(testPaginationState.PagingDetails.isPagerChange){
        callGetAllTest();
        resetTestPagingState();
    }
}, [testPaginationState.PagingDetails.isPagerChange]);


const [disablePagination, setDisablePagination] = useState<boolean>(false);
    
const onPageChanged = (pageNumber: number) => {
    updateTestPageNumber(pageNumber);
};

const onPageLimitChanged = (evt: any) => {
    updateTestPageSize(parseInt(evt.target.value));
};
  const handleRemoveFromCompariosn = (testIdToBeRemoved: any) => {
    const remainingTests = selectedTestsForComparison.filter((testId: any) => testId != testIdToBeRemoved)
    if (remainingTests) {
      setSelectedTestsForComparison(remainingTests);
    }
  }

  const handleAddToComparison = (id: number) => {
    const testToBeAdded = allTest.find((old: ITestInfo) => old.TestId === id);
    if (testToBeAdded && selectedTestsForComparison) {
      setSelectedTestsForComparison([...selectedTestsForComparison, testToBeAdded.TestId]);
    }
  }

  return (
    <>
      {props.showDeletePopup && (
        <DeletePopup modalClosed={props.deletePopupToggleHandler} isLoading= {deleteTestState.inProgress} Id={deleteTestId} Title={deleteTestName} deleteHandler={handleDeleteTest} />
      )}
      <div className="dashboardTopbar">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-5 col-12 text-sm-start text-center">
              <p className="text-dark"><FormattedMessage id="total_test" />: {testPaginationState.PagingDetails.TotalRecords}</p>
            </div>
            {/* <div className="col-sm-7 col-12 text-sm-end text-center">
              <p className="px-3 rounded text-white d-inline-block bg-danger fw-normal"><FormattedMessage id="paid_test_remaining" />: {testDataList.PaidTestLeft.toString()}</p>
            </div> */}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {allTest.map((item, index) => {

            return (
              <>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-4" key={index}>
                  <div className="card border-card">
                    <div className="card-body text-center">
                      {
                        item.IsPaidTest && item.TrainerId == null &&
                        <span className="agent-badge bg-white text-uppercase" title={`${formatMessage('is_paid')}`}>
                          <svg width="20" height="20" viewBox="-160.55 -160.55 1556.10 1556.10" xmlns="http://www.w3.org/2000/svg" fill="#ff0000" stroke="#ff0000" stroke-width="0.012350000000000002" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#ff0000" stroke-width="148.2"><path d="M0 449h1235l-999 726L618 0l382 1175L0 449z" fill="#ff0000"></path></g><g id="SVGRepo_iconCarrier"><path d="M0 449h1235l-999 726L618 0l382 1175L0 449z" fill="#ff0000"></path></g></svg>
                        </span>
                      }
                      {item.TestType === "LIFE" ? (
                        <button className="btn btn-circle mt-2 btn-secondary ">
                          {item.TestType}
                        </button>
                      ) : item.TestType === "TRAINING" ? (
                        <button className="btn btn-circle mt-2 btn-warning ">
                          {item.TestType}
                        </button>
                      ) : (
                        <button className="btn btn-circle mt-2 btn-success ">
                          {item.TestType}
                        </button>
                      )}
                      {(item.TrainerId != null) ? <span className='trainers-badge'><FormattedMessage id="Trainers_invitation" /></span> : ''}
                      <h4 className="card-title mb-0 card_name" title={item.UserEmail}>{item.UserEmail}</h4> 
                      <p className="card-subtitle text-muted small">
                        <svg enable-background="new 0 0 64 64" viewBox="0 0 64 64" fill="currentColor"><g id="Captions"><g><path d="m56.562 17.372c-.316-5.255-4.704-9.372-9.989-9.372h-2.573v-2c0-1.104-.896-2-2-2s-2 .896-2 2v2h-16v-2c0-1.104-.896-2-2-2s-2 .896-2 2v2h-2.573c-5.286 0-9.674 4.117-9.989 9.372-.593 9.884-.582 19.91.033 29.799.312 5.022 4.335 9.045 9.357 9.357 5.033.313 10.102.469 15.171.469 5.068 0 10.138-.156 15.171-.469 5.022-.312 9.045-4.335 9.357-9.357.616-9.884.627-19.909.035-29.799zm-4.026 29.551c-.188 3.013-2.601 5.426-5.613 5.613-9.902.615-19.944.615-29.846 0-3.013-.188-5.426-2.601-5.613-5.613-.473-7.603-.57-15.286-.317-22.923h41.707c.252 7.64.155 15.323-.318 22.923zm-30.536-30.923c1.104 0 2-.896 2-2v-2h16v2c0 1.104.896 2 2 2s2-.896 2-2v-2h2.573c3.173 0 5.807 2.465 5.996 5.611.047.794.067 1.593.106 2.389h-41.35c.04-.796.059-1.595.106-2.389.189-3.146 2.822-5.611 5.996-5.611h2.573v2c0 1.104.896 2 2 2z" /><circle cx="22" cy="33" r="3" /><circle cx="32" cy="33" r="3" /><circle cx="22" cy="43" r="3" /><circle cx="42" cy="33" r="3" /><circle cx="42" cy="43" r="3" /><circle cx="32" cy="43" r="3" /></g></g><g id="Icons" /></svg>
                        {item.TestDate}
                      </p>
                      
                      <div className="text-center ">
                        <div className="mt-4">
                          <Link className="btn btn-sm btn-outline-secondary" to={`${RoutesConstants.TestResult}/${item.TestId}`} target="_blank" title={formatMessage('view_result')}>
                            <svg height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><rect height="6.5" rx=".75" width="5.5" x="2.25" y="14.25"/><rect height="9.5" rx=".75" width="5.5" x="9.25" y="11.25"/><rect height="13.5" rx=".75" width="5.5" x="16.25" y="7.25"/><path d="m22 22.75h-20a.75.75 0 0 1 0-1.5h20a.75.75 0 0 1 0 1.5z"/><path d="m19 5.75a.76.76 0 0 1 -.75-.75v-2.25h-2.25a.75.75 0 0 1 0-1.5h3a.76.76 0 0 1 .75.75v3a.76.76 0 0 1 -.75.75z"/><path d="m5 10.75a.75.75 0 0 1 -.48-1.33l6-5a.76.76 0 0 1 1 0l1.53 1.58 5.47-4.57a.75.75 0 1 1 1 1.16l-6 5a.75.75 0 0 1 -1 0l-1.52-1.59-5.52 4.58a.78.78 0 0 1 -.48.17z"/></g></svg>
                          </Link>
                          {
                            (selectedTestsForComparison.filter((testId: any) => testId === item.TestId).length == 1) && item.TestId ?
                              <button className='btn btn-primary btn-sm mx-2' onClick={() => handleRemoveFromCompariosn(item.TestId)} title={formatMessage('remove_from_comparison')}>
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                  viewBox="0 0 512 512"><path d="M76.8,212.3c0-63.6,51.6-115.2,115.2-115.2c63.6,0,115.2,51.6,115.2,115.2c0,63.6-51.6,115.2-115.2,115.2
                              C128.4,327.5,76.8,275.9,76.8,212.3z"/>
                                  <path d="M67.6,371c31.6-13.7,72.9-17.9,124.4-17.9c51.5,0,92.9,4.2,124.5,17.9c34.4,14.9,55.3,40.4,66.2,76.4
                              c5.1,16.9-7.5,33.7-25,33.7H26.3c-17.5,0-30.1-16.9-25-33.8C12.3,411.4,33.2,385.9,67.6,371L67.6,371z"/>
                                  <path d="M384,97.1h-51.2c-14.1,0-25.6,11.5-25.6,25.6s11.5,25.6,25.6,25.6H384h51.2h51.2c14.1,0,25.6-11.5,25.6-25.6
                              s-11.5-25.6-25.6-25.6h-51.2H384z"/>
                                </svg>
                              </button>
                              :
                              <button className='btn btn-outline-primary btn-sm mx-2' onClick={() => handleAddToComparison(item.TestId)} title={formatMessage('add_to_comparison')}>
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                  viewBox="0 0 512 512"><path d="M76.8,212.3c0-63.6,51.6-115.2,115.2-115.2c63.6,0,115.2,51.6,115.2,115.2c0,63.6-51.6,115.2-115.2,115.2
                              C128.4,327.5,76.8,275.9,76.8,212.3z"/>
                                  <path d="M67.6,371c31.6-13.7,72.9-17.9,124.4-17.9c51.5,0,92.9,4.2,124.5,17.9c34.4,14.9,55.3,40.4,66.2,76.4
                              c5.1,16.9-7.5,33.7-25,33.7H26.3c-17.5,0-30.1-16.9-25-33.8C12.3,411.4,33.2,385.9,67.6,371L67.6,371z"/>
                                  <path d="M435.2,45.9c0-14.1-11.5-25.6-25.6-25.6S384,31.7,384,45.9v51.2h-51.2c-14.1,0-25.6,11.5-25.6,25.6s11.5,25.6,25.6,25.6H384
                              v51.2c0,14.1,11.5,25.6,25.6,25.6s25.6-11.5,25.6-25.6v-51.2h51.2c14.1,0,25.6-11.5,25.6-25.6s-11.5-25.6-25.6-25.6h-51.2V45.9z"/>
                                </svg>
                              </button>
                          }
                          <button className="btn btn-sm btn-outline-danger " onClick={() => handleDeleteClick(item.TestId, item.UserEmail)} title={formatMessage('delete')} disabled={item.TrainerId != null}>
                            <svg version="1.1" id="Layer_1" viewBox="0 0 512 512" >
                              <g>
                                <g>
                                  <path d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
			L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
			c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
			c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
			c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
			c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"/>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <path d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
			c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
			c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"/>
                                </g>
                              </g>
                            </svg>

                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}

        </div>
        <ul className="d-flex flex-column flex-md-row justify-content-between mt-4">
            <li className="page-count-no mb-2">
              <FormattedMessage id="showing_result_for" />
              <select
                data-testid="pageSelect"
                className="pageLimit ms-2"
                onChange={onPageLimitChanged}
                value={testPaginationState.PagingDetails.PageSize}
              >
                {pageLimitOptions.map((op: any) => (
                   <option key={op} value={op}>
                    {op}
                   </option>
                 ))}
              </select>
            </li>
              <li className="page-number">
               <Pagination
                  isRefreshPage={testPaginationState.PagingDetails.isRefreshPage}
                  totalPages={testPaginationState.PagingDetails.TotalPages}
                  pageLimit={testPaginationState.PagingDetails.PageSize}
                  onPageChanged={onPageChanged}
                  disablePagination={disablePagination}
                />
             </li>
         </ul> 
      </div>
    </>
  )
}

export default DeletePopupHOC(observer(TestView));