import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { useStore } from "../../../../contexts/StoreProvider";
import { ISendEventMail } from "../../../../models/Form/ISendEventMail";
import LoaderButton from "../../../../shared-components/Button/LoaderButton";
import { formatMessage } from "../../../../translations/format-message";
import { observer } from "mobx-react";
import { SendEventMailValidationSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../../shared-components/Label/Label";
import FormikMultiEmailField from "../../../../shared-components/FormikFormHandlers/FormikMultiEmailField";
import FormikFormTextArea from "../../../../shared-components/FormikFormHandlers/FormikFormTextArea";

interface IProps {
    modalClosed: () => void;
    isLoading: boolean;
    eventId?: any;
    eventLink: string | undefined;
}

const SendEventMailPopup = (props: IProps) => {
    const { agentStore } = useStore();
    const { sendMail, resetSendMailEvent, getSendEventMailDetail, sendMailEventState} = agentStore;
    useEffect(() => {
        if (sendMailEventState.success) {
          resetSendMailEvent();
          props.modalClosed();
        }
      }, [sendMailEventState.success]);

    return (
        <>
            <Modal show={true} centered
                onHide={props.modalClosed}
                backdrop="static"
                scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="send_mail" />   
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={getSendEventMailDetail}
                    validationSchema={SendEventMailValidationSchema}
                    enableReinitialize
                    validateOnBlur={false}
                    onSubmit={async (values: ISendEventMail) => {
                        sendMail(values,props.eventId);
                    }}
                    render={({ values, handleBlur, handleChange, errors, touched, setTouched, setFieldTouched }) => {
                        return (
                        <Form id="sendEventMailForm" className="modal-form">
                            <div className='d-flex flex-row flex-wrap'>
                                <div className='form-group flex-grow-1'>
                                    <Label
                                        className="fw-bold"
                                        label="event_name"
                                        htmlFor="event_name" />
                                    {/* <Label text="EventName"/> */}
                                    <span> : </span>
                                    <p className='d-inline'>{values.EventName}</p>
                                </div>

                                <div className='form-group flex-grow-1'>
                                    <Label
                                        className="fw-bold"
                                        label="test_version"
                                        htmlFor="test_version" />
                                    {/* <label className='fw-bold'><FormattedMessage id="test_version" /></label> */}
                                    <span> : </span>
                                    <p className='d-inline'>{values.TestType}</p>
                                </div>

                                <div className='form-group flex-grow-1'>
                                    <Label
                                        className="fw-bold"
                                        label="coupon"
                                        htmlFor="coupon" />
                                    {/* <label className='fw-bold'><FormattedMessage id="coupon" /></label> */}
                                    <span> : </span>
                                    <p className='d-inline'>{values.CouponCode ? values.CouponCode : "N/A"}</p>
                                </div>
                            </div>
                            <div className='form-group' >
                                <Label
                                    required={true}
                                    className="fw-bold"
                                    label="email"
                                    htmlFor="email" />
                                <Field
                                    data-testid="RecipientUserEmail"
                                    name="RecipientUserEmail"
                                    as={FormikMultiEmailField}
                                />
                            </div>
                            <div className='form-group' >
                                <Label
                                    required={true}
                                    className="fw-bold"
                                    label="message"
                                    htmlFor="message" />
                                <Field
                                    data-testid="MessageTitle"
                                    className="form-control"
                                    name="MessageTitle"
                                    placeholder="email_title"
                                    as={FormikFormTextArea}
                                />
                            </div>
                            <div className='form-group' >
                                <Field
                                    data-testid="MessageBody"
                                    className="form-control"
                                    name="MessageBody"
                                    placeholder="email_body"
                                    as={FormikFormTextArea}
                                />
                            </div>
                            <p className='py-2 px-2 mb-2 bg-info text-white rounded'>{props.eventLink}</p>
                        </Form>
                    );
                }}
                >
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    data-testid="Cancel"
                    variant="secondary"
                    onClick={props.modalClosed}
                    disabled={props.isLoading}
                    className="btnOkSize">
                    <FormattedMessage id="button_cancel" />
                </Button>
                <LoaderButton
                    type="submit"
                    variant="primary"
                    form="sendEventMailForm"
                    id="RegisterButton"
                    className="btnOkSize"
                    isLoading={props.isLoading}
                    text="send_mail"
                />
            </Modal.Footer>
            </Modal>
        </>
    )
}

export default observer(SendEventMailPopup)
