import { IAgentState } from "../../models/state/IAgentState";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import {initialState as GetAllEventsInitialState, initialEventInfoState as GetEventSummary} from '../initial-state/get-all-event'
import {IEvent, IEventInfo} from '../../models/IEvent';
import * as baseService from '../service/base-service';
import IAgentInfo, { IEventSummaryInfo, IGetAllEvents, IGetEventSummary } from "../../models/response/IAgentResponse";
import URLConstants from "../../constants/url.constants";
import toast, { Toaster } from 'react-hot-toast';
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { formatMessage } from "../../translations/format-message";
import IAddEvent, { IUpdateEvent } from "../../models/Form/IAddUpdateEvent";
import { ISendEventMail } from "../../models/Form/ISendEventMail";
import { initialState as addEventInitialState} from "../initial-state/add-event-state";
import { initialSendEventMailState as sendEventMailInitialState} from "../initial-state/send-event-mail-state";
import { IPagingDetails } from "../../models/response/IPagingDetails";
import { initialPaginationStateValue } from "../../constants/options.constant";
import RoutesConstants from "../../shared-components/Routes/routes.constants";

export class AgentStore implements IAgentState{
  eventsDataList: IGetAllEvents = GetAllEventsInitialState;
  eventSummary: IGetEventSummary = GetEventSummary;
  eventDetail: IAddEvent = addEventInitialState;
  sendEventMailDetail: any = undefined;
  couponDiscount:any;
  error = '';
  intialPagingState :IPagingDetails = initialPaginationStateValue;

  
  initialStateValue = {
    success: false,
    error: '',
    inProgress: false
  }

  getAllEventsState = {...this.initialStateValue}
  deleteEventState = {...this.initialStateValue}
  addUpdateEventState = {...this.initialStateValue}
  sendMailEventState = {...this.initialStateValue}
  validateCouponCodeState = {...this.initialStateValue}
  eventPaginationState = {...this.intialPagingState}

  constructor(){
    makeObservable(this,{
      getAllEvents: action,
      allEvents: computed,
      allEventSummary: computed,
      eventsDataList: observable,
      eventSummary: observable,
      getAllEventsState: observable,
      addEvent: action,
      updateEvent: action,
      deleteEvent:action,
      downloadFile:action,
      getEventDetail: computed,
      eventDetail: observable,
      setSelectedEventUsingId: action,
      getSendEventMailDetail: computed,
      validateCouponCodeState:observable,
      resetAddUpdateEvent: action,
      addUpdateEventState: observable,
      couponDiscount:observable,
      resetSendMailEvent: action,
      sendMailEventState: observable,

      resetDeleteEvent:action,
      deleteEventState:observable,
      
      // Helper Functions
      getNormalisedDate: action,

      eventPaginationState:observable
    })
  }
  
  resetAddUpdateEvent = () => {
    this.eventDetail = addEventInitialState;
    this.addUpdateEventState = { ...this.initialStateValue }
  }

  resetDeleteEvent = () => {
    this.deleteEventState = { ...this.initialStateValue }
  }

  resetSendMailEvent = () => {
    this.sendEventMailDetail = sendEventMailInitialState;
    this.sendMailEventState = { ...this.initialStateValue }
  }

  /*
    Function to set the selected event using Id, for editing event and sending event mail.
  */
  setSelectedEventUsingId = (eventId:number) => {
    const eventsDataListTyped = this.eventsDataList;
    const eventsDataListArray = toJS(this.eventsDataList);
    const eventIndex = eventsDataListArray.Events.findIndex((event:IAgentInfo) => event.EventId === eventId);
    if(eventIndex !== -1){
      this.eventDetail = eventsDataListTyped.Events[eventIndex];
      this.sendEventMailDetail = sendEventMailInitialState;
      this.sendEventMailDetail.UserId = this.eventDetail.UserId;
      this.sendEventMailDetail.UserEmailId = this.eventDetail.UserEmailId;
      this.sendEventMailDetail.FirstName = this.eventDetail.FirstName;
      this.sendEventMailDetail.LastName = this.eventDetail.LastName;
      this.sendEventMailDetail.EventName = this.eventDetail.EventName;
      this.sendEventMailDetail.CreatedDate = this.eventDetail.CreatedDate;
      this.sendEventMailDetail.UpdatedDate = this.eventDetail.UpdatedDate; 
      this.sendEventMailDetail.TestType = this.eventDetail.TestType; 
      this.sendEventMailDetail.CouponCode = this.eventDetail.CouponCode;
      this.sendEventMailDetail.DiscountPercentage = this.eventDetail.DiscountPercentage; 
      this.couponDiscount = this.eventDetail.DiscountPercentage; 
    }
    if(this.sendEventMailDetail.CouponCode === "" || this.sendEventMailDetail.CouponCode === null){
       this.validateCouponCodeState.success = false;
    }
    else{
      this.validateCouponCodeState.success = true;
    }
  }

  /*
    Function to add/create new event.
  */
  addEvent = (events: IAddEvent) => {
    this.addUpdateEventState.inProgress = true;
    const url = URLConstants.Event;
    const data = {
      EventName: events.EventName?.trim(),
      CreatedDate: events.CreatedDate,
      TestType: events.TestType,
      CouponCode: events.CouponCode?.trim(),
      Note: events.Note?.trim()
    };
    return baseService.postRequest(url, data)
    .then((response: IApiResponse<IApiSuccessResponse<IAgentInfo>>) => {
        this.eventsDataList.Events.push(response.data.Data);
        this.addUpdateEventState.success = true;
        toast.success(formatMessage("added_success"));
      })
      .catch((err: string) => {
        // console.log(err);
        this.addUpdateEventState.error = err;
        toast.error(formatMessage(err));
        return null;
      })
      .finally(
        action(() => {
          this.addUpdateEventState.inProgress = false;
        })
      );
  }
  
  /*
    Function to update existing event.
  */
  updateEvent =  (event: IUpdateEvent, eventId: number) => {
    this.addUpdateEventState.inProgress = true;
    const url = URLConstants.Event;
    const data = {
      EventId: eventId,
      EventName: event.EventName?.trim(),
      CreatedDate: event.CreatedDate,
      UpdatedDate: Math.floor(new Date(Date.now()).getTime() / 1000).toString(),
      TestType: event.TestType,
      CouponCode: event.CouponCode?.trim(),
      Note: event.Note?.trim()
    };
    return baseService.putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateEventState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.addUpdateEventState.success = true;
          toast.success(formatMessage("updated_success"));
        }
      })
      .catch((err: string) => {
        // console.log(err);
        this.addUpdateEventState.error = err;
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateEventState.inProgress = false;
        })
      );
  }

  /*
    Function to get all events.
  */
  getAllEvents= (currentPage:number,pagerSize:number) => {
    this.getAllEventsState.inProgress = true;
    const url = URLConstants.Event + "/List" + '?PageNo=' + currentPage + '&PageSize=' + pagerSize;
    this.eventsDataList = GetAllEventsInitialState;
    return baseService
    .getRequest(url)
    .then((response: IApiResponse<IApiSuccessResponse<IGetAllEvents>>) => {
      if (response.data.Error) {
          this.error = response.data.Message;
          this.getAllEventsState.error = response.data.Message;
          this.getAllEventsState.success = false;
          toast.error(formatMessage(response.data.Message));
      }
      else{
          // console.log(response)
          this.eventsDataList.Events = response.data.Data.Events;
          this.getAllEventsState.success = true;
          this.eventPaginationState.PagingDetails = response.data.Data.PagingDetails
      }
    })
    .catch((err: string) => {
      // console.log(err);
      this.getAllEventsState.error = err;
      this.getAllEventsState.success = false;
      toast.error(formatMessage(err));
    })
    .finally(
      action(() => {
        this.getAllEventsState.inProgress = false;
      })
    );
};

/* 
  function to update page size
  */
  updateEventPageSize =(value:number,isRefreshPage:boolean = false)=>{
    this.eventPaginationState.PagingDetails.PageSize = value;
    this.eventPaginationState.PagingDetails.isPagerChange = true;
    if(isRefreshPage)
    {
      this.eventPaginationState.PagingDetails.isRefreshPage = true;
    }
  }

/* 
function to update current page number
*/
  updateEventPageNumber =(value:number,isRefreshPage:boolean = false)=>{
    this.eventPaginationState.PagingDetails.PageNo = value;
    this.eventPaginationState.PagingDetails.isPagerChange = true;
    if(isRefreshPage)
    {
      this.eventPaginationState.PagingDetails.isRefreshPage = true;
    }
  }
  resetEventPagingState = ()=>{
      this.eventPaginationState.PagingDetails.isPagerChange = false;
      this.eventPaginationState.PagingDetails.isRefreshPage = false;
  }
  /*
    Function to get the summary of selected events.
  */
  getAllEventSummary = (eventId:number)=>{
    const url = URLConstants.Event + "/Summary/"+eventId;
    this.eventSummary = GetEventSummary;
    return baseService.getRequest(url)
    .then((response: IApiResponse<IApiSuccessResponse<IAgentInfo>>) => {
      if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
      }
      else{
        this.eventSummary.EventSummary = response.data.Data;
        // console.log(this.eventSummary);
      }
    })    
    .catch((err: string) => {
      // console.log(err);
      toast.error(formatMessage(err));
    })
  }

  /*
    Function to fetch the event info when we open the Send Event Mail dialog.
  */
  getEventInfo = (eventInfoArray: any) => {
    const eventInfoList: IEventSummaryInfo[] = [];
    eventInfoArray.forEach((eventInfo: any) => {
      const singleEventInfo: IEventSummaryInfo = {
        RecipientUserEmail: eventInfo.RecipientUserEmail,
        MessageTitle: eventInfo.MessageTitle,
        MessageBody: eventInfo.MessageBody,
        MailOpenedDate: this.getNormalisedDate(eventInfo.MailOpenedDate),
        MailViewedDate: this.getNormalisedDate(eventInfo.MailViewedDate),
        TestId: eventInfo.TestId,
        TestTakenDate: this.getNormalisedDate(eventInfo.TestTakenDate),
        MailSendDate: this.getNormalisedDate(eventInfo.MailSendDate),
        MailUpdatedDate: this.getNormalisedDate(eventInfo.MailUpdatedDate),
        Amount: eventInfo.Amount,
        CouponCode: eventInfo.CouponCode
      };
      eventInfoList.push(singleEventInfo);
    });
    return eventInfoList;
  };

  /*
    Function to send the event mail.
  */
  sendMail = (eventInfo: ISendEventMail, eventId: number) => {
    this.sendMailEventState.inProgress = true;
    const url = URLConstants.SendMail;
    const data = {
      EventId: eventId,
      RecipientUserId: eventInfo.RecipientUserId
      ? eventInfo.RecipientUserId.map((emailId: number) => emailId)
      : [],
      RecipientUserEmail: Array.isArray(eventInfo.RecipientUserEmail)
      ? eventInfo.RecipientUserEmail.map((email: string) => email.trim())
      : [eventInfo.RecipientUserEmail].filter(Boolean),
      MessageTitle: eventInfo.MessageTitle?.trim(),
      MessageBody: eventInfo.MessageBody?.trim(),
    };
    return baseService
    .postRequest(url, data)
    .then((responseData: any) => {
      if (responseData.status === 200)
      {
        this.sendMailEventState.success = true;
        toast.success(formatMessage("sent_success"));
        // console.log(responseData);
      }
    })
    .catch((err: string) => {
      // console.log(err);
      this.sendMailEventState.error = err;
      toast.error(formatMessage(err));
      return null;
    })
    .finally(
      action(() => {
        this.sendMailEventState.inProgress = false;
      })
    );
  }

  /*
    Function to delte the event.
  */
  deleteEvent = (eventId: number) => {
    this.deleteEventState.inProgress = true;

    const url = URLConstants.Event + "/" +eventId;
    return baseService
    .deleteRequest(url)
    .then((responseData: any) => {
      if (responseData.status === 200)
      {
        this.deleteEventState.success = true;
        toast.success(formatMessage("deleted_success"));
        // console.log(responseData);
      }
    })
    .catch((err: string) => {
      // console.log(err);
      this.deleteEventState.error = err;
      toast.error(formatMessage(err));
      return null;
    })
    .finally(
      action(() => {
        this.deleteEventState.inProgress = false;
      })
    );
  }

  /*
    Function to reset the deletestate 
  */
  resetDeleteState=()=>{
    this.deleteEventState = {...this.initialStateValue}
  }

  downloadFile = async(eventId: number)=>{
    this.setSelectedEventUsingId(eventId);
    await this.getAllEventSummary(eventId);
    const data = [
    {
        AgentId: this.eventDetail.UserId,
        AgentName: `${this.eventDetail.FirstName} ${this.eventDetail.LastName}`,
        EventName: this.eventSummary.EventSummary.EventName,
        CreatedDate: this.getNormalisedDate(this.eventSummary.EventSummary.CreatedDate),
        Test: this.eventSummary.EventSummary.TestType,
        Coupon: this.eventSummary.EventSummary.CouponCode,
        EventMails: this.eventSummary.EventSummary.EventMails.map((eventMail) => ({
          MailAddress: eventMail.RecipientUserEmail,
          DateMailSent: this.getNormalisedDate(eventMail.MailSendDate),
          DateOpeningMail: this.getNormalisedDate(eventMail.MailOpenedDate),
          DateConnectingWeb: this.getNormalisedDate(eventMail.MailUpdatedDate),
          TestTakenDate: this.getNormalisedDate(eventMail.TestTakenDate),
          PaidDate: "0",
          PaidAmount: eventMail.Amount,
          Coupon: eventMail.CouponCode,
      })),
    }
];
// console.log(data);
const basicHeaders = [formatMessage('agent_number'), formatMessage('agent'), formatMessage('event_name'), formatMessage('date'), formatMessage('test_version'), formatMessage('coupon')];
const basicHeadersForData = ["AgentId", "AgentName", "EventName", "CreatedDate", "Test", "Coupon"];
const eventMailHeaders = [
    formatMessage('email'),
    formatMessage('date_mail_sent'),
    // formatMessage('date_mail_open'),
    // formatMessage('date_connect_web'),
    formatMessage('test_taken'),
    formatMessage('is_paid'),
    formatMessage('coupon'),
];
const eventMailHeadersForData = [
  "MailAddress",
  "DateMailSent",
  // "DateOpeningMail",
  // "DateConnectingWeb",
  "TestTakenDate",
  "PaidAmount",
  "Coupon",
];

const csvContent = [
  "Event Info",
  basicHeaders.join(','), // CSV header for basic information
  ...data.map(row => basicHeadersForData.map(header => (row as any)[header]).join(',')), // CSV rows for basic information
  '', // Empty line to separate the two sets of headers and rows
  "Event Result",
  eventMailHeaders.join(','), // CSV header for EventMails information
  ...data.map(row => {
      if (row.EventMails && Array.isArray(row.EventMails)) {
          return row.EventMails.map(eventMail =>
            eventMailHeadersForData.map(header => (eventMail as any)[header]).join(',')
          ).join('\n');
      } else {
          return eventMailHeadersForData.map(() => '').join(','); // If EventMails is missing or not an array, fill with empty values
      }
  }) // CSV rows for EventMails information
].join('\n');

// console.log(csvContent);
this.downloadCSV(csvContent,this.eventSummary.EventSummary.EventName)
  }
  
  downloadCSV = (content: string, fileName: string)=> {
      const blob = new Blob([content], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
  }

  validateCouponCode = (couponCode:string)=>{
    this.validateCouponCodeState.inProgress = true;
    const url = URLConstants.ValidateCoupon + "?couponCode="+couponCode;
    return baseService
    .getRequest(url)
    .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
      if (response.data.Error) {
          this.error = response.data.Message;
          this.validateCouponCodeState.error = this.error;
          // toast.error(formatMessage(response.data.Message));
      }
      else{
          // console.log(response)
          this.couponDiscount = response.data.Data;
          this.validateCouponCodeState.success = true;
      }
    })
    .catch((err: string) => {
      // console.log(err);
      this.validateCouponCodeState.error = err;
      // toast.error(formatMessage(err));
    })
    .finally(
      action(() => {
        this.validateCouponCodeState.inProgress = false;
      })
    );
  }

  resetValidateCouponState = () => {
    this.validateCouponCodeState = {...this.initialStateValue};
  }
  /*
    Computed function to get all the events.
  */
  get allEvents(): IEvent[] {
    if (this.eventsDataList?.Events && this.eventsDataList.Events?.length > 0 ) {
      //Sorting according to CreatedDate.
      // const allEventsSortedData = toJS(this.eventsDataList?.Events).sort((a,b) => Number(a.CreatedDate) - Number(b.CreatedDate));
      return this.eventsDataList.Events.map((event: IEvent) => {
        return {
          UserId: event?.UserId,
          UserEmailId: event?.UserEmailId,
          FirstName: event?.FirstName,
          LastName: event?.LastName,
          EventId: event.EventId,
          EventName: event.EventName,
          CreatedDate: this.getNormalisedDate(event.CreatedDate),
          UpdatedDate:event.UpdatedDate,
          TestType:event.TestType,
          CouponCode:event.CouponCode,
          Note:event.Note,
          EventLink: this.getEventLink(event.EventId, event.TestType)
        };
      });
    }
    return [];
  }

  /*
    Helper Function to get the event link.
  */
  getEventLink = (eventId: number, testType: string) => {
    let eventLink: string = `${window.location.origin}${RoutesConstants.Life}/?eventId=${eventId}`;

    if (testType == 'LIFE') {
      eventLink = `${window.location.origin}${RoutesConstants.Life}/?eventId=${eventId}`;
    }
    else if (testType == 'TRAINING') {
      eventLink = `${window.location.origin}${RoutesConstants.Training}/?eventId=${eventId}`;
    }
    else if (testType == 'RACING') {
      eventLink = `${window.location.origin}${RoutesConstants.Racing}/?eventId=${eventId}`;
    }
    return eventLink;
  }

  /*
    Computed function to get the event summary.
  */
  get allEventSummary(): IGetEventSummary {
    if (this.eventSummary?.EventSummary?.EventId !== -1) {
      //Sorting according to CreatedDate.
      const eventSummary = ((toJS(this.eventSummary)));
      // console.log(eventSummary);
      const mails : Array<IEventSummaryInfo> = eventSummary?.EventSummary.EventMails.map((event: IEventSummaryInfo) => {
        return {
          RecipientUserId: event.RecipientUserId,
          RecipientUserEmail: event.RecipientUserEmail,
          MessageTitle: event.MessageTitle,
          MessageBody: event.MessageBody,
          TestId: event.TestId,
          TestTakenDate: this.getNormalisedDate(event.TestTakenDate),
          MailOpenedDate: this.getNormalisedDate(event.MailOpenedDate),
          MailViewedDate: this.getNormalisedDate(event.MailViewedDate),
          MailSendDate: this.getNormalisedDate(event.MailSendDate),
          MailUpdatedDate: this.getNormalisedDate(event.MailUpdatedDate),
          Amount: event.Amount,
          CouponCode: event.CouponCode
        };
      });
      eventSummary.EventSummary.EventMails = mails;
      return eventSummary;
    }
    return GetEventSummary;
  }

  /*
    Computed function to get the event detail when we open the edit event dialog.
  */
  get getEventDetail(): IAddEvent {
    if (this.eventDetail)
      return {
          UserId:this.eventDetail?.UserId,
          EventName: this.eventDetail?.EventName,
          TestType:this.eventDetail?.TestType,
          CouponCode:this.eventDetail?.CouponCode,
          Note: this.eventDetail?.Note,
          CreatedDate:this.eventDetail?.CreatedDate,
          UpdatedDate:this.eventDetail?.UpdatedDate,
          DiscountPercentage:this.eventDetail.DiscountPercentage
      };
    return addEventInitialState;
  }

  /*
    Computed function to get the event summary detail when we open the send event mail dialog.
  */
  get getSendEventMailDetail(): ISendEventMail {
    if (this.sendEventMailDetail)
      return {
        UserId: this.sendEventMailDetail?.UserId,
        EventName: this.sendEventMailDetail?.EventName,
        TestType: this.sendEventMailDetail?.TestType,
        CouponCode: this.sendEventMailDetail?.CouponCode,
        Note: this.sendEventMailDetail?.Note,
        CreatedDate: this.sendEventMailDetail?.CreatedDate,
        UpdatedDate: this.sendEventMailDetail?.UpdatedDate,
        EventId: this.sendEventMailDetail?.EventId,
        RecipientUserId: this.sendEventMailDetail?.RecipientUserId,
        RecipientUserEmail: this.sendEventMailDetail?.RecipientUserEmail,
        MessageTitle: this.sendEventMailDetail?.MessageTitle,
        MessageBody: this.sendEventMailDetail?.MessageBody,
        DiscountPercentage:this.sendEventMailDetail.DiscountPercentage
      };
    return sendEventMailInitialState;
  }

  /*
    Helper Function which converts unixTimestamp to Date in the format (dd/MM/yyyy, hh:mm:ss)
  */
    getNormalisedDate = (unixTimestamp: string) => {
      if (Number.parseInt(unixTimestamp)) {
        var date = new Date(Number(unixTimestamp) * 1000);
        const formattedDate = date.toLocaleDateString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
    
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour12: false, // Use 24-hour format
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        });
    
        return `${formattedDate} ${formattedTime}`;
      }
      return "-";
    }
    
    
}
export default new AgentStore();
