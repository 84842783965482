import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ITestInfo } from "../../models/ITestInfo";
import RoutesConstants from "../../shared-components/Routes/routes.constants";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useStore } from "../../contexts/StoreProvider";
import * as XLSX from 'xlsx';
import { ICategoryInfo, IResultInfo, ISubCategoryInfo } from "../../models/response/ITestInterpretation";
import { observer } from "mobx-react";
import { formatMessage } from "../../translations/format-message";

export interface IProps {
    selectedTestsForComparison : number[];
    removeAll: () => void;
} 

const TestCompare = ({selectedTestsForComparison, removeAll}: IProps) => {
    const navigate = useNavigate();
    const { testStore } = useStore();
    const { getTestInterpretation, allGraphData, allSurveyData, allTestInfo, allTwoDiResult, testInterpratationState, resetTestInterpretation, resetTestState } = testStore;
    const [ isDownloadRequired, setIsDownloadRequired] = useState<boolean>(false);

    const handleCompareTests = () => {
        if (selectedTestsForComparison.length == 1) {
            const singleTestId = selectedTestsForComparison[0];
            window.open(`${RoutesConstants.TestResult}/${singleTestId}`, '_blank', 'noopener, noreferrer');
        }
        else if (selectedTestsForComparison.length > 1) {
            const testIdsURL = selectedTestsForComparison.map((testId: number) => {
                return `testId=${testId}`
            })
            window.open(`${RoutesConstants.TestResultCompare}?${testIdsURL.join('&')}`, '_blank', 'noopener, noreferrer');
        }
        else {
            // toast.error("Please select at least 2 test for comparison.");
            toast.error("Error occured.");
        }
    }

    const handleDownloadTestResults = () => {
        if (selectedTestsForComparison.length > 0) {
            const testIds = selectedTestsForComparison.map((testId: number) => testId);
            getTestInterpretation(testIds);
            setIsDownloadRequired(true);
        }
        // else {
        //     toast.error("Please select at least 2 test for comparison.");
        // }
    }

    const exportToExcel = (data: any, catList: any, fileName = 'Test Comparison.xlsx') => {
        const template = {
            'user': '',
            'name': '',
            'subCat1': 0,
            'subCat2': 0,
            'subCat3': 0,
            'subCat4': 0,
            'subCat5': 0,
            'subCat6': 0,
            'subCat7': 0,
            'subCat8': 0,
            'subCat9': 0,
            'subCat10': 0,
            'subCat11': 0,
            'subCat12': 0,
            'subCat13': 0,
            'subCat14': 0,
            'subCat15': 0,
            'subCat16': 0,
            'subCat17': 0,
            'subCat18': 0,
            'subCat19': 0,
            'subCat20': 0,
            'subCat21': 0,
            'subCat22': 0,
            'subCat23': 0,
            'subCat24': 0,
            'subCat25': 0,
            'subCat26': 0,
            'subCat27': 0,
            'longTerm': 0,
            'shortTerm': 0
        }
        const stringifiedTemplate = JSON.stringify(template);
        const excelData = data.map(((d: any, index: number) => {
            const entity = JSON.parse(stringifiedTemplate);
            entity.user = d[0][1]
            entity.name = d[1][1]
            entity.subCat1 = d[2][1]
            entity.subCat2 = d[3][1]
            entity.subCat3 = d[4][1]
            entity.subCat4 = d[5][1]
            entity.subCat5 = d[6][1]
            entity.subCat6 = d[7][1]
            entity.subCat7 = d[8][1]
            entity.subCat8 = d[9][1]
            entity.subCat9 = d[10][1]
            entity.subCat10 = d[11][1]
            entity.subCat11 = d[12][1]
            entity.subCat12 = d[13][1]
            entity.subCat13 = d[14][1]
            entity.subCat14 = d[15][1]
            entity.subCat15 = d[16][1]
            entity.subCat16 = d[17][1]
            entity.subCat17 = d[18][1]
            entity.subCat18 = d[19][1]
            entity.subCat19 = d[20][1]
            entity.subCat20 = d[21][1]
            entity.subCat21 = d[22][1]
            entity.subCat22 = d[23][1]
            entity.subCat23 = d[24][1]
            entity.subCat24 = d[25][1]
            entity.subCat25 = d[26][1]
            entity.subCat26 = d[27][1]
            entity.subCat27 = d[28][1]
            entity.longTerm = d[29][1]
            entity.shortTerm = d[30][1]
            return entity;
        }));

        const customMergeHeaders: string[] = ['', ''];
        catList.forEach((item: any) => {
            customMergeHeaders.push(formatMessage(item[0])); // Push the string itself
            // Push empty strings based on the count
            for (let i = 0; i < item[1] - 1; i++) {
                customMergeHeaders.push("");
            }
        });
        customMergeHeaders.push('');
        customMergeHeaders.push('');
        
        const customHeader =  [...data[0].map((cat: any) => cat[0])];

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, [customMergeHeaders, customHeader]);
        XLSX.utils.sheet_add_json(worksheet, excelData, {
            skipHeader: true,
            origin: -1,
        });
        worksheet["!merges"] = [
            { s: { c: 1, r: 0 }, e: { c: 1, r: 0 } },
            { s: { c: 2, r: 0 }, e: { c: 3, r: 0 } },
            { s: { c: 4, r: 0 }, e: { c: 5, r: 0 } },
            { s: { c: 6, r: 0 }, e: { c: 8, r: 0 } },
            { s: { c: 9, r: 0 }, e: { c: 10, r: 0 } },
            { s: { c: 11, r: 0 }, e: { c: 12, r: 0 } },
            { s: { c: 13, r: 0 }, e: { c: 14, r: 0 } },
            { s: { c: 15, r: 0 }, e: { c: 16, r: 0 } },
            { s: { c: 17, r: 0 }, e: { c: 18, r: 0 } },
            { s: { c: 19, r: 0 }, e: { c: 20, r: 0 } },
            { s: { c: 21, r: 0 }, e: { c: 22, r: 0 } },
            { s: { c: 23, r: 0 }, e: { c: 25, r: 0 } },
            { s: { c: 26, r: 0 }, e: { c: 28, r: 0 } },
        ];
          
        XLSX.utils.book_append_sheet(workbook, worksheet, formatMessage('test_result'));
        XLSX.writeFile(workbook, fileName);
    }

    const exportToExcel_FreeTest = (data: any, catList: any, fileName = 'Test Comparison.xlsx') => {
        const template = {
            'user': '',
            'name': '',
            'subCat1': 0,
            'subCat2': 0,
            'subCat3': 0,
            'subCat4': 0,
            'subCat5': 0,
            'subCat6': 0,
            'subCat7': 0,
            'longTerm': 0,
            'shortTerm': 0
        }
        const stringifiedTemplate = JSON.stringify(template);
        const excelData = data.map(((d: any, index: number) => {
            const entity = JSON.parse(stringifiedTemplate);
            entity.user = d[0][1]
            entity.name = d[1][1]
            entity.subCat1 = d[2][1]
            entity.subCat2 = d[3][1]
            entity.subCat3 = d[4][1]
            entity.subCat4 = d[5][1]
            entity.subCat5 = d[6][1]
            entity.subCat6 = d[7][1]
            entity.subCat7 = d[8][1]
            entity.longTerm = d[9][1]
            entity.shortTerm = d[10][1]
            return entity;
        }));

        const customMergeHeaders: string[] = ['', ''];
        catList.forEach((item: any) => {
            customMergeHeaders.push(formatMessage(item[0])); // Push the string itself
            // Push empty strings based on the count
            for (let i = 0; i < item[1] - 1; i++) {
                customMergeHeaders.push("");
            }
        });
        customMergeHeaders.push('');
        customMergeHeaders.push('');
        
        const customHeader =  [...data[0].map((cat: any) => cat[0])];

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, [customMergeHeaders, customHeader]);
        XLSX.utils.sheet_add_json(worksheet, excelData, {
            skipHeader: true,
            origin: -1,
        });
        worksheet["!merges"] = [
            { s: { c: 1, r: 0 }, e: { c: 1, r: 0 } },
            { s: { c: 2, r: 0 }, e: { c: 3, r: 0 } },
            { s: { c: 4, r: 0 }, e: { c: 5, r: 0 } },
            { s: { c: 6, r: 0 }, e: { c: 8, r: 0 } },
            { s: { c: 9, r: 0 }, e: { c: 10, r: 0 } },
        ];
          
        XLSX.utils.book_append_sheet(workbook, worksheet, formatMessage('test_result'));
        XLSX.writeFile(workbook, fileName);
    }

    useEffect(() => {
        if (testInterpratationState.success && isDownloadRequired) {
            const subCategories = allGraphData.flatMap((category: ICategoryInfo) => 
                                    [...category.SubCategoryList]
                            );
            const catList = allGraphData.map((category: ICategoryInfo) => {
                const key = category.CategoryKey;
                const subCat = category.SubCategoryList.length;
                return [key, subCat];
            })
            const testDetailArray = allTestInfo.map((test: ITestInfo) => {
                            const userInfo = [formatMessage("User"), test.UserEmail];
                            const userName = [formatMessage("name"), (test.FirstName && test.LastName) ? `${test.FirstName} ${test.LastName}`: ""];
                            const subCategoryData =  [...subCategories.map((subCat: ISubCategoryInfo) => {
                                const res = subCat.Results.find((result: IResultInfo) => result.TestId === test.TestId)
                                return [formatMessage(subCat.SubCategoryKey), res?.Result];
                            })];
                            const shortTermQ = allSurveyData.find(x => x.Key == "SHORT_TERM");
                            const shortTerm = [formatMessage('SHORT_TERM'), (shortTermQ?.SelectedOption?.find(x => x.TestId == test.TestId)?.ReplyKey) ? formatMessage(`${test.TestType}_${shortTermQ.SelectedOption.find(x => x.TestId == test.TestId)?.ReplyKey}`): ""];
                            const longTermQ = allSurveyData.find(x => x.Key == "LONG_TERM");
                            const longTerm = [formatMessage('LONG_TERM'), (longTermQ?.SelectedOption?.find(x => x.TestId == test.TestId)?.ReplyKey) ? formatMessage(`${test.TestType}_${longTermQ.SelectedOption.find(x => x.TestId == test.TestId)?.ReplyKey}`): ""];
                            return [userInfo, userName, ...subCategoryData, longTerm, shortTerm];

            });
            const data = [...testDetailArray]
            if (catList.length == 3) {
                exportToExcel_FreeTest(data, catList);
            }
            else {
                exportToExcel(data, catList);
            }

            resetTestInterpretation();
            setIsDownloadRequired(false);
        }
    }, [testInterpratationState.success, isDownloadRequired]);

    return (
            (selectedTestsForComparison.length > 0) && (
                <>
                    <div className='comparisonWrap'>
                        <button className='btn btn-info me-2' onClick={() => handleDownloadTestResults()}><i className="fa fa-download"></i> <FormattedMessage id="Trainer_Download" /> - {selectedTestsForComparison.length}</button>
                        <button className='btn btn-warning me-2' onClick={() => handleCompareTests()}><i className="fas fa-file-signature"></i> <FormattedMessage id="Trainer_See_Results" /> - {selectedTestsForComparison.length}</button>
                        <button className='btn btn-danger me-2 my-2' onClick={removeAll}><i className="fa fa-trash-alt"></i> <FormattedMessage id="Trainer_Remove_All" /></button>
                    </div>
                </>
          )
    )
}

export default observer(TestCompare);
