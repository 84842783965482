import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import InputTag from '../InputTag/InputTag';
import { formatMessage } from '../../../translations/format-message';
import { TransactionData } from './TransactionData';
import { FormattedMessage } from 'react-intl';
import { useStore } from '../../../contexts/StoreProvider';
import { IPaymentInfo } from '../../../models/response/IAdminResponse';
import CustomPagination from '../../../shared-components/Pagination/CustomPagination';

const currentYear = new Date().getFullYear();
const startYear = 2000;
const FilterYear = [{ id: "", value: "--Select Year--" }];

for (let i = startYear; i <= currentYear; i++) {
    FilterYear.push({
        id: (i - startYear).toString(),
        value: i.toString()
    });
}

export const FilterMonth = [
    { id: "", "value": "--Select Month--" },
    { id: "0", value: "01" },
    { id: "1", value: "02" },
    { id: "2", value: "03" },
    { id: "4", value: "04" },
    { id: "5", value: "05" },
    { id: "6", value: "06" },
    { id: "7", value: "07" },
    { id: "8", value: "08" },
    { id: "9", value: "09" },
    { id: "10", value: "10" },
    { id: "11", value: "11" },
    { id: "12", value: "12" },
];
const Transactions = () => {

    const { adminStore } = useStore();
    const { getAllPayments, paymentDataList, allPayments,updatePaymentSearchingText,updatePaymentMonthFilteredText,updatePaymentYearFilteredText, resetPaymentFilter, paymentPaginationState,updatePaymentPageNumber, updatePaymentPageSize, resetPaymentPagingState }  = adminStore;
    const[selectedMonth,setSelectedMonth] = useState("");
    const[selectedYear,setSelectedYear] = useState("");
    const [searchText, setSearchText] = useState<string>("");
    const [isSearchPerformed, setIsSearchPerformed] = useState<boolean>(false);
    
    useEffect(() => {
        if(paymentPaginationState.PagingDetails.isPagerChange){
            resetPaymentFilter();
            callGetAllPayment();
            resetPaymentPagingState();
        }
    }, [paymentPaginationState.PagingDetails.isPagerChange]);

    useEffect(() => {
        if (searchText.replace(/\s+/g,' ').trim() === '' && isSearchPerformed) {
            setIsSearchPerformed(() => false);
            setSearchText(() => '');
            callGetAllPayment();
        }
    }, [searchText]);

    const handleClick = () => {
        const year = selectedYear === "--Select Year--" ? '' : selectedYear;
        const month = selectedMonth === "--Select Month--" ? '' : selectedMonth;

        updatePaymentMonthFilteredText(month);
        updatePaymentYearFilteredText(year);
        callGetAllPayment();
    }

    const handleSearch=()=>{
        updatePaymentPageNumber(1, true);
        setIsSearchPerformed(() => true);
    }
    const callGetAllPayment=()=>{
        getAllPayments(paymentPaginationState.PagingDetails.PageNo, paymentPaginationState.PagingDetails.PageSize, searchText.replace(/\s+/g,' ').trim());
    }
   
    const handleKeyDown=(event:any)=>{
        if (event.key === 'Enter' && searchText.replace(/\s+/g,' ').trim() !== '') {
            handleSearch();
        }
      }
      const onPageLimitChanged = (evt: any) => {
        updatePaymentPageSize(parseInt(evt.target.value));
    };
    const onPageChanged = (pageNumber: number) => {
        updatePaymentPageNumber(pageNumber);
    };
    const [disablePagination, setDisablePagination] = useState<boolean>(false);
    
    const getFormattedCurrency = (amount: number) => {
        return amount.toLocaleString('en-JP', {
            style: 'currency',
            currency: 'JPY',
        });
    }
    return (
        <>
        <div className="dashboardTopbar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-xl-6">
                        <div className='row align-items-center g-0'>
                            <div className='col-sm-7 col-8 text-sm-end text-center'>
                                <InputTag icon={true} size="search" inputType="text"  inputValue={setSearchText} inputvalue={searchText} onKeyDown={handleKeyDown}  clickedFn={handleSearch} placeholderText={formatMessage("search")} FaIcon={faSearch} />
                            </div>
                            <div className='col-sm-5 col-4 text-xl-start text-end'>
                                <p className="text-dark d-inline-block"><FormattedMessage id="Transactions_Total" /> {getFormattedCurrency(paymentDataList.TotalAmount)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 text-md-end text-center mt-xl-0 mt-2">
                        <div className=' input-group'>
                                <select
                                    data-testid="pageSelect"
                                    className="form-control pageLimit mb-0"
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                >
                                    {
                                        FilterMonth.map((item: any) => {
                                            return <>
                                                <option key={item} value={item.value} >
                                                    {item.value}
                                                </option>
                                            </>
                                        })
                                    }
                                </select>
                                <select
                                    data-testid="pageSelect"
                                    className="form-control pageLimit mb-0"
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                >
                                    {
                                        FilterYear.map((item: any) => {
                                            return <>
                                                <option key={item} value={item.value}>
                                                    {item.value}
                                                </option>
                                            </>
                                        })
                                    }
                                </select>
                                <button className='btn btn-primary' onClick={() => handleClick()}> <FormattedMessage id="Transactions_Apply" /></button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className="table-responsive">
                <table className="table tableDesign">
                    <thead>
                        <tr>
                            <th><FormattedMessage id="Transactions_Name" /></th>
                            <th><FormattedMessage id="Transactions_Email" /></th>
                            <th><FormattedMessage id="Transactions_Date" /></th>
                            <th><FormattedMessage id="Transactions_Product" /></th>
                            <th><FormattedMessage id="Transactions_Amount" /></th>
                            <th><FormattedMessage id="Transactions_Coupon" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allPayments.map((payment: IPaymentInfo) => {
                                return <>
                                    <tr>
                                        <td>{payment.FirstName + " " + payment.LastName}</td>
                                        <td>{payment.Email}</td>
                                        <td>{payment.PaymentDate}</td>
                                        <td>{payment.ProductNameStr}</td>
                                        <td>{payment.Amount}</td>
                                        <td>{payment.CouponCode ? payment.CouponCode : "N/A" }</td>
                                    </tr>
                                </>
                            })
                        }
                    </tbody>
                </table>
            </div>
            { 
                <CustomPagination
                    onPageLimitChanged={onPageLimitChanged}
                    onPageChanged={onPageChanged}
                    pageSize={paymentPaginationState.PagingDetails.PageSize}
                    totalPages={paymentPaginationState.PagingDetails.TotalPages}
                    isRefreshPage={paymentPaginationState.PagingDetails.isRefreshPage}
                    disablePagination={ allPayments.length > 0 ? disablePagination : true }
                />
                }
        </div>
        </>
    );
}

export default observer(Transactions);
