import { action, computed, makeObservable, observable, toJS } from "mobx"
import URLConstants from "../../constants/url.constants"
import { IAdminState } from "../../models/state/IAdminState"
import ILabelInfo, { ICategoryInfo, ICouponInfo, IFilterInfo, IGetAllCoupons, IGetAllLabels, IGetAllPayments, IGetAllStatsData, IGetAllTest, IGetAllThreshold, IGetAllUsers, IPaymentInfo, IThresholdInfo, ITrainerList, ITrainerListInfo } from "../../models/response/IAdminResponse";
import { initialState as GetAllLabelsInitialState } from '../initial-state/get-all-label-state'
import { initialStatState as GetAllStatsInitialState } from '../initial-state/get-all-stat-state'
import { initialState as GetAllCouponsInitialState } from '../initial-state/get-all-coupon-state'
import { initialState as GetAllPaymentsInitialState } from '../initial-state/get-all-payment-state'
import { initialState as GetAllTestInitialState } from '../initial-state/get-all-test-state'
import { initialState as GetAllUsersInitialState } from '../initial-state/get-all-user-state'
import { initialState as GetAllThresholdInitialState } from '../initial-state/get-all-threshold-state'
import { initialState as AddUpdateUserInitialState } from '../initial-state/registration-state'
import * as baseService from '../service/base-service';
import toast from "react-hot-toast";
import { formatMessage } from "../../translations/format-message";
import { ILabel } from "../../models/ILabel";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { IUser } from "../../models/IUser";
import { ICoupon } from "../../models/ICoupon";
import { CouponState } from "../initial-state/coupon-state";
import IAddCoupn from "../../models/Form/IAddUpdateCoupon";
import { IPagingDetails } from "../../models/response/IPagingDetails";
import { IThreshold } from "../../models/state/IThreshold";
import { ProductType, UserTypeNumber } from "../../constants/enum";
import { IAddThreshold } from "../../models/Form/IAddUpdateThreshold";
import { IFilterTest, ITestInfo } from "../../models/ITestInfo";
import { IAddUser } from "../../models/Form/IAddUser";
import { IStatState } from "../../models/state/IStatState";
import { TrainerListState } from "../initial-state/get-TrainerInfo-state";
import { IPaymentSettingInfo, IPaymentSettingList } from "../../models/response/IPaymentSettingValue";
import { PaymentSettingsInfoState, SettingsInfoState } from "../initial-state/get-PaymentSettingsInfo-state";
import {FiguresInfoState} from "../initial-state/get-figure";
import {initialPaginationStateValue} from "../../constants/options.constant"
import { IFigures } from "../../models/response/IFigures";
import { ICreator, ICreatorInfo } from "../../models/ICreatorInfo";

export interface ITestFilter {
  heading: string; 
  value: IFilterTest[]
}

export interface ITestFilterState {
  testFilterList: ITestFilter[];
}

export class AdminStore implements IAdminState {
  usersDataList: IGetAllUsers = GetAllUsersInitialState
  selectedUser: any = undefined;
  labelDataList: IGetAllLabels = GetAllLabelsInitialState
  statsDataList: IGetAllStatsData = GetAllStatsInitialState
  couponDataList: IGetAllCoupons = GetAllCouponsInitialState
  paymentDataList: IGetAllPayments = GetAllPaymentsInitialState
  couponList: IAddCoupn = CouponState;
  thresholdList: IGetAllThreshold = GetAllThresholdInitialState
  testDataList: IGetAllTest = GetAllTestInitialState
  trainerList: ITrainerList = TrainerListState;
  paymentSettingList: IPaymentSettingList = PaymentSettingsInfoState;
  FiguresList: IFigures = FiguresInfoState;
  creatorList: ICreator[] = [];

  intialPagingState:IPagingDetails = initialPaginationStateValue;
  error = '';
  initialStateValue = {
    success: false,
    error: '',
    inProgress: false
  }

  /* 
   initial state for sorting and seraching
 */
  initialSearchAndFilterStateValue = {
    searchText: '',
    filter: ''
  }

  /* 
   initial state for Test filter
 */

  initialTestFilterStateValue: ITestFilterState = {
  testFilterList:[
    {
        heading: "Trainer",
        value: [
            {
                id: 0,
                checked: false,
                name: "",
                // email: ""
            },
        ]
    },
    {
        heading: "Roles",
        value: [
            // {
            //     id: 1,
            //     checked: false,
            //     name: "Free",
            // },
            {
                id: 2,
                checked: false,
                name: "Personal",
            },
            {
                id: 3,
                checked: false,
                name: "Trainer",
            },
        ]
    },
    {
        heading: "Test Types",
        value: [
            {
                id: 1,
                checked: false,
                name: "LIFE",
            },
            {
                id: 2,
                checked: false,
                name: "TRAINING",
            },
            {
                id: 3,
                checked: false,
                name: "RACING",
            },
            {
                id: 4,
                checked: false,
                name: "JOB",
            },
        ]
    },
  ]
  }

initialRolesAndTestTypeFilterStateValue = {
    SearchText: '',
    Roles: '',
    TestType: '',
    trainerList:'',
  }

  initialPaymentFilterStateValue = {
    userNameToBeSearched: '',
    monthToBeFiltered: '',
    yearToBeFiltered:''
  }

  getUserListState = { ...this.initialStateValue }
  addUpdateUserState = { ...this.initialStateValue }
  deleteUserState = { ...this.initialStateValue }
  updateLabelState = { ...this.initialStateValue }
  getAllCouponsState = { ...this.initialStateValue }
  addUpdateCouponState = { ...this.initialStateValue }
  deleteCouponState = { ...this.initialStateValue }
  deleteTestState = { ...this.initialStateValue }
  labelPaginationState = {...this.intialPagingState}
  userPaginationState = {...this.intialPagingState}
  testPaginationState = {...this.intialPagingState}
  paymentPaginationState = {...this.intialPagingState}
  couponPaginationState = {...this.intialPagingState}
  searchingFilterState = { ...this.initialSearchAndFilterStateValue }
  searchingFilterUserTabState = { ...this.initialSearchAndFilterStateValue }
  getAllThresholdState = {...this.initialStateValue}
  addupdateThresholdState = { ...this.initialStateValue }
  getStatState = { ...this.initialStateValue }
  deleteThresholdState = { ...this.initialStateValue }
  testFilterState = { ...this.initialRolesAndTestTypeFilterStateValue }
  getTrainerListState = { ...this.initialStateValue }
  paymentFilterState = {...this.initialPaymentFilterStateValue}
  getPaymentSettingsState = {...this.initialStateValue}
  addUpdatePaymentSettingState = { ...this.initialStateValue }
  getAllTestState = {...this.initialStateValue};
  testFilterStateValue = {...this.initialTestFilterStateValue}
  getTestTakenCountState = {...this.initialStateValue}
  getTotalTrainersCountState = {...this.initialStateValue}
  getFiguresCountState = {...this.initialStateValue}
  updateFiguresState = { ...this.initialStateValue }
  getCreatorInfoState = {...this.initialStateValue}
  updateCreatorInfoState = {...this.initialStateValue}
  uploadCreatorImageState = {...this.initialStateValue}

  constructor() {
    makeObservable(this, {
      usersDataList: observable,
      labelDataList: observable,
      updateLabelState: observable,
      couponDataList: observable,
      paymentDataList: observable,
      getUserListState: observable,
      addUpdateUserState: observable,
      deleteUserState: observable,
      getAllCouponsState: observable,
      addUpdateCouponState: observable,
      deleteCouponState: observable,
      testDataList: observable,
      trainerList: observable,
      deleteTestState: observable,
      labelPaginationState:observable,
      userPaginationState:observable,
      testPaginationState:observable,
      paymentPaginationState:observable,
      searchingFilterState: observable,
      searchingFilterUserTabState: observable,
      thresholdList: observable,
      getAllThresholdState: observable,
      addupdateThresholdState: observable,
      getStatState: observable,
      selectedUser: observable,
      deleteThresholdState: observable,
      testFilterState: observable,
      statsDataList: observable,
      paymentFilterState:observable,
      paymentSettingList:observable,
      getPaymentSettingsState:observable,
      getAllTestState: observable,
      couponPaginationState:observable,
      testFilterStateValue: observable,
      getTestTakenCountState: observable,
      getTotalTrainersCountState: observable,
      getFiguresCountState: observable,
      getCreatorInfoState: observable,
      updateCreatorInfoState: observable,
      uploadCreatorImageState: observable,
      FiguresList:observable,
      creatorList: observable,
      getAllUsers: action,
      getTrainerList: action,
      resetUserListState: action,
      setSelectedUserUsingEmail: action,
      createProfile: action,
      updateProfile: action,
      resetAddUpdateUserState: action,
      deleteUser: action,
      resetDeleteUser: action,
      getAllLabels: action,
      getAllPayments: action,
      getAllCoupons: action,
      resetGetAllCouponsState: action,
      createCoupon: action,
      updateCoupon: action,
      deleteCoupon: action,
      getAllTest: action,
      deleteTest: action,
      resetUpdateLabelState: action,
      resetDeleteTest: action,
      //updatePageSize:action,
      //updatePageNumber:action,
      updateSearchingText: action,
      updateFilteredText: action,
      getAllThreshold: action,
      updateThreshold: action,
      createThreshold: action,
      deleteThreshold: action,
      updatePaymentYearFilteredText:action,
      updatePaymentMonthFilteredText:action,
      updatePaymentSearchingText:action,
      resetPaymentFilter: action,
      getPaymentSetting:action,
      resetGetAllTestState: action,
      getCreatorsInfo: action,
      updateCreatorInfo: action,
      uploadCreatorImage: action,

      allTrainerList: computed,
      allUsers: computed,
      getSelectedUserDetails: computed,
      allLabel: computed,
      allCoupons: computed,
      getCouponDetail: computed,
      allThreshold: computed,
      allTest: computed,
      allstatsData: computed,
      allPaymentSettingInfo:computed,
      allTestFilter: computed,
      allFigures:computed,
      allCreators: computed,
    })
  }

  /* ************************** USERS ************************** */

  /*
    Function used to reset the userListState.
  */
  resetUserListState = () => {
    this.getUserListState = { ...this.initialStateValue }
  }

  /*
    Function used to reset the addUpdateUserState.
  */
  resetAddUpdateUserState = () => {
    this.selectedUser = AddUpdateUserInitialState;
    this.addUpdateUserState = { ...this.initialStateValue }
  }

  /*
    Function used to reset the deleteUserState.
  */
  resetDeleteUser = () => {
    this.selectedUser = AddUpdateUserInitialState;
    this.deleteUserState = { ...this.initialStateValue }
  }

  resetUpdateLabelState = () => {
    this.updateLabelState = { ...this.initialStateValue }
  }

  updateFilterStateValue=(heading: string, key: IFilterTest)=>{
    this.testFilterStateValue.testFilterList.map((item: ITestFilter) => {
        if (item.heading == heading) {
            item.value.map((data: IFilterTest) => {
                if (data === key) {
                  // console.log(data);
                  // console.log(key);
                  data.checked = !data.checked;
                  return;
                }
            })
        }
    })
   }
  

  /*
    Function to get all the users from the backend.
  */
  getAllUsers = (currentPage:number,pagerSize:number,textToBeSearch: string | null, filter: string | null) => {
    this.getUserListState.inProgress = true;
    var url = URLConstants.GetAllUsers + '?PageNo=' + currentPage + '&PageSize=' + pagerSize;
    if (textToBeSearch != null) {
      url += "&textToBeSearched=" + textToBeSearch;
    }
    if (filter != null) {
      url += "&filter=" + filter;
    }
    this.usersDataList = GetAllUsersInitialState;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IGetAllUsers>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
          this.getUserListState.error = response.data.Message;
        }
        else {
          this.usersDataList = response.data.Data;
          this.getUserListState.success = true;
          this.userPaginationState.PagingDetails = response.data.Data.PagingDetails;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
        this.getUserListState.error = err;
      })
      .finally(
        action(() => {
          this.getUserListState.inProgress = false;
        })
      );
  };


  getTrainerList = () => {
    this.getTrainerListState.inProgress = true;
    var url = URLConstants.GetAllTrainerList;
    this.trainerList = TrainerListState;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<ITrainerList>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
          this.getTrainerListState.error = response.data.Message;
        }
        else {
          this.trainerList = response.data.Data;
          this.testFilterStateValue.testFilterList[0].value = response.data.Data.TrainerList.map((trainer: ITrainerListInfo) => ({
            id: trainer.TrainerId,
            checked: false,
            name: trainer.TrainerEmailId,
            // email: trainer.TrainerEmailId
          }));
          this.getTrainerListState.success = true;
        }
      })
      .catch((err: string) => {
        toast.error(formatMessage(err));
        this.getTrainerListState.error = err;
      })
      .finally(
        action(() => {
          this.getTrainerListState.inProgress = false;
        })
      );
  }

  get allTestFilter(): ITestFilter[] {
    if (this.testFilterStateValue.testFilterList && toJS(this.testFilterStateValue.testFilterList).length > 0) {
      return this.testFilterStateValue.testFilterList?.map((testFilter: ITestFilter) => {
        return {
          heading: testFilter.heading,
          value: testFilter.value
        }
      });
    }
    return [];
  }

  get allTrainerList(): ITrainerListInfo[] {
    if (this.trainerList && this.trainerList.TrainerList?.length > 0) {
      return this.trainerList.TrainerList?.map((group: ITrainerListInfo) => {
        return {
          TrainerId: group.TrainerId,
          TrainerEmailId: group.TrainerEmailId,
          TrainerName: group.TrainerName
        };
      });
    }
    return [];
  }

  getPaymentSetting = () =>{
    this.getPaymentSettingsState.inProgress = true;
    var url = URLConstants.GetPaymentSettingsValue;
    return baseService
    .getRequest(url)
    .then((response:IApiResponse<IApiSuccessResponse<IPaymentSettingList>>)=>{
      if(response.data.Error){
        this.error = response.data.Message;
        toast.error(formatMessage(response.data.Message));
        this.getPaymentSettingsState.error = response.data.Message;
      }
      else{
        this.paymentSettingList = response.data.Data;
        this.getPaymentSettingsState.success = true;
      }
    })
    .catch((err: string) => {
      toast.error(formatMessage(err));
      this.getPaymentSettingsState.error = formatMessage(err);
    })
    .finally(
      action(() => {
        this.getPaymentSettingsState.inProgress = false;
      })
    );
  }

  get allPaymentSettingInfo(): IPaymentSettingInfo[]{
    const settings = toJS(this.paymentSettingList?.PaymentSettingList);
    if (settings && settings?.length > 0) {
      return settings?.map((paymentSetting: IPaymentSettingInfo) => {
        return {
          Id: paymentSetting.Id,
          JPY: paymentSetting.JPY,
          EUR: paymentSetting.EUR,
          USD: paymentSetting.USD,
          ProductType: paymentSetting.ProductType
        };
      });
    }
    return [];
  }


    /*
    Function to update the coupons
  */
    updatePaymentSettingsInfo = (settings: IPaymentSettingList) => {
      this.addUpdatePaymentSettingState.inProgress = true;
      var url = URLConstants.UpdatePaymentSettingsValue;

      const data = settings.PaymentSettingList
        .map((payment:any)=>({
          Id:payment.Id,
         JPY: Number(payment.JPY),
         EUR: Number(payment.EUR),
         USD: Number(payment.USD),
         ProductType: Number(payment.ProductType),
        }))
        const requestData = {
          PaymentListVM: data,
          PreferredCurrency: Number(settings.PreferredCurrency)
        };
      return baseService.putRequest(url, requestData)
        .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
          if (response.data.Error) {
            this.addUpdatePaymentSettingState.error = response.data.Message;
            toast.error(formatMessage(response.data.Message));
          }
          else {
            this.addUpdatePaymentSettingState.success = true;
            toast.success(formatMessage("updated_success"));
          }
        })
        .catch((err: string) => {
          // console.log(err);
          toast.error(formatMessage(err));
        })
        .finally(
          action(() => {
            this.addUpdatePaymentSettingState.inProgress = false;
          })
        );
    };
  
    get  getPaymentDetail(): IPaymentSettingList {
      const settings:IPaymentSettingList={PaymentSettingList:
      this.paymentSettingList.PaymentSettingList.map(setting => ({
        Id: setting.Id,
        JPY: setting.JPY,
        EUR: setting.EUR,
        USD: setting.USD,
        ProductType: setting.ProductType
      })),
      PreferredCurrency:this.paymentSettingList.PreferredCurrency
    }
    return settings;
    };
  /* 
  function to update current page size
  */
   updateUserPageSize =(value:number,isRefreshPage:boolean = false)=>{
     this.userPaginationState.PagingDetails.PageSize = value;
     this.userPaginationState.PagingDetails.isPagerChange = true;
     if(isRefreshPage)
     {
       this.userPaginationState.PagingDetails.isRefreshPage = true;
     }
   }

  /* 
  function to update current page number
  */
   updateUserPageNumber =(value:number,isRefreshPage:boolean = false)=>{
     this.userPaginationState.PagingDetails.PageNo = value;
     this.userPaginationState.PagingDetails.isPagerChange = true;
     if(isRefreshPage)
     {
       this.userPaginationState.PagingDetails.isRefreshPage = true;
     }
    }

    resetUserPagingState = ()=>{
      this.userPaginationState.PagingDetails.isPagerChange = false;
      this.userPaginationState.PagingDetails.isRefreshPage = false;
  }

  /* 
    Function to update searching text
  */
  updateSearchingUserText = (value: string) => {
    this.searchingFilterUserTabState.searchText = value;
  }

  /* 
    Function to update filter text
  */
  updateFilteredUserText = (value: string) => {
    this.searchingFilterUserTabState.filter = value;
  }

  /*
    Function to set the selected coupon using Id, for editing coupon.
  */
  setSelectedUserUsingEmail = (email: string) => {
    const usersDataListTyped = this.usersDataList;
    const usersDataListArray = toJS(this.usersDataList);
    const selectedUserIndex = usersDataListArray.Users.findIndex((user: IUser) => user.Email === email);
    if (selectedUserIndex !== -1) {
      this.selectedUser = usersDataListTyped.Users[selectedUserIndex];
    }
  }

  /*
    Function to create trainer account/profile.
  */
  createProfile = (user: IAddUser) => {
    this.addUpdateUserState.inProgress = true;
    var url = URLConstants.CreateTrainerAccount;
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const data = {
      Email: user.Email,
      FirstName: user.FirstName,
      LastName: user.LastName,
      UserType: Number(user.UserType),
      SubscriptionExpiryDate: user.SubscriptionExpiryDate ? new Date(new Date(user.SubscriptionExpiryDate).getTime() - tzoffset).toISOString() : null,
      TrainerInvitesAvailable: user.TrainerInvitesAvailable ? user.TrainerInvitesAvailable : 0,
      Is_Agent: user.IsAgent && user.IsAgent.toString() === "true" ? true : false,
      DateOfBirth: new Date(user.DateOfBirth).toISOString(),
      Gender: Number(user.Gender),
      Nation: user.Nation?.trim(),
      CountryOfResidence: user.CountryOfResidence?.trim(),
    }

    return baseService.postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateUserState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.addUpdateUserState.success = true;
          toast.success(formatMessage('added_success'));
        }
      })
      .catch((err: string) => {
        // console.log(err);
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateUserState.inProgress = false;
        })
      );
  }


  /*
    Function to update the profile of the selected user.
  */
  updateProfile = (values: IAddUser) => {
    this.addUpdateUserState.inProgress = true;
    var url = URLConstants.UpdateUser;

    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const data = {
      OldEmail: this.selectedUser.Email,
      Email: this.selectedUser.Email, // TODO: Don't allow the user to edit the mail/or send it in the APIs
      // Email: values.Email,
      FirstName: values.FirstName,
      LastName: values.LastName,
      UserType: Number(values.UserType),
      SubscriptionExpiryDate: values.SubscriptionExpiryDate ? new Date(new Date(values.SubscriptionExpiryDate).getTime() - tzoffset).toISOString() : null,
      TrainerInvitesAvailable: values.TrainerInvitesAvailable ? values.TrainerInvitesAvailable : 0,
      Is_Agent: values.IsAgent && values.IsAgent.toString() === "true" ? true : false,
    }

    return baseService.putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateUserState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.addUpdateUserState.success = true;
          toast.success(formatMessage("updated_success"));
        }
      })
      .catch((err: string) => {
        // console.log(err);
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateUserState.inProgress = false;
        })
      );
  }


  /*
    Function used to delete the user from the database.
  */
  deleteUser = (userId: number) => {
    this.deleteUserState.inProgress = true;
    var url = URLConstants.DeleteUser + "?userId=" + userId;

    return baseService.deleteRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.deleteUserState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.deleteUserState.success = true;
          toast.success(formatMessage('deleted_success'));
        }
      })
      .catch((err: string) => {
        // console.log(err);
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.deleteUserState.inProgress = false;
        })
      );
  }


  /* ************************** LABELS ************************** */

  /*
    Function to get all the labels from the backend based on the specified search text and sorting criteria
  */
  getAllLabels = (currentPage:number,pagerSize:number,textToBeSearched: string | null, sortBy: string | null) => {
    var url = URLConstants.GetAllLabels + '?PageNo=' + currentPage + '&PageSize=' + pagerSize;

    if (textToBeSearched != null) {
      url += "&textToBeSearched=" + encodeURIComponent(textToBeSearched);
    }
    if (sortBy != null) {
      url += "&sortBy=" + sortBy;
    }
    this.labelDataList = GetAllLabelsInitialState;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IGetAllLabels>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.labelDataList = response.data.Data;
           this.labelPaginationState.PagingDetails = response.data.Data.PagingDetails;
          // console.log(response.data.Data.PagingDetails)
        }
      })
      .catch((err: string) => {
        // console.log(err);
        toast.error(formatMessage(err));
      })
  };

  /* 
  function to update page size
  */
   updatePageSize =(value:number,isRefreshPage:boolean = false)=>{
     this.labelPaginationState.PagingDetails.PageSize = value;
     this.labelPaginationState.PagingDetails.isPagerChange = true;
     if(isRefreshPage)
     {
       this.labelPaginationState.PagingDetails.isRefreshPage = true;
     }
   }

  /* 
  function to update current page number
  */
   updatePageNumber =(value:number,isRefreshPage:boolean = false)=>{
     this.labelPaginationState.PagingDetails.PageNo = value;
     this.labelPaginationState.PagingDetails.isPagerChange = true;
     if(isRefreshPage)
     {
       this.labelPaginationState.PagingDetails.isRefreshPage = true;
     }
    }
    resetLabelPagingState = ()=>{
      this.labelPaginationState.PagingDetails.isPagerChange = false;
      this.labelPaginationState.PagingDetails.isRefreshPage = false;
  }
  /* 
    function to update searching text
  */
  updateSearchingText = (value: string) => {
    this.searchingFilterState.searchText = value;
  }

  /* 
     function to update sorting text
   */
  updateFilteredText = (value: string) => {
    this.searchingFilterState.filter = value;
  }

  /*
    Function to update existing labels.
  */
  updateLabel = (label: ILabelInfo[]) => {

    this.updateLabelState.inProgress = true;
    const url = URLConstants.UpdateLabel;

    const dataArray = label
      .filter(labels => labels.id !== null) // Filter out objects where id is null
      .map(labels => ({
        id: labels.id,
        it: labels.it,
        en: labels.en,
        jp: labels.jp,
      }));
    return baseService.putRequest(url, { LabelsList: dataArray })
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.updateLabelState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.updateLabelState.success = true;
          toast.success(formatMessage("saved_success"));
        }
      })
      .catch((err: string) => {
        // console.log(err);
        this.updateLabelState.error = err;
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.updateLabelState.inProgress = false;
        })
      );
  }



  /* ************************** Coupons ************************** */

  /*
    Function to set the selected coupon using Id, for editing coupon.
  */
  setSelectedCouponUsingId = (couponId: number) => {
    const couponsDataListTyped = this.couponDataList;
    const couponsDataListArray = toJS(this.couponDataList);
    const couponIndex = couponsDataListArray.CouponList.findIndex((event: ICouponInfo) => event.id === couponId);
    if (couponIndex !== -1) {
      this.couponList = couponsDataListTyped.CouponList[couponIndex];
    }
  }

  /*
    Function to reset the state
  */
  resetAddUpdateCoupon = () => {
    this.couponList = CouponState;
    this.addUpdateCouponState = { ...this.initialStateValue }
  }

  resetDeleteCoupon = () => {
    this.deleteCouponState = { ...this.initialStateValue }
  }

  /*
    Function to get all the coupons
  */
  getAllCoupons = (currentPage:number,pagerSize:number) => {
    var url = URLConstants.GetAllCoupons+ '?PageNo=' + currentPage + '&PageSize=' + pagerSize;
    this.getAllCouponsState.inProgress = true;
    this.couponDataList = GetAllCouponsInitialState;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IGetAllCoupons>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          this.getAllCouponsState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.couponDataList = response.data.Data;
          this.couponPaginationState.PagingDetails = response.data.Data.PagingDetails;
          this.getAllCouponsState.success = true;
        }
      })
      .catch((err: string) => {
        // console.log(err);
        toast.error(formatMessage(err));
        this.getAllCouponsState.error = formatMessage(err);
      })
      .finally(
        action(() => {
          this.getAllCouponsState.inProgress = false;
        })
      );
  };

  resetGetAllCouponsState = () => {
    this.couponDataList = GetAllCouponsInitialState;
    this.getAllCouponsState = {...this.initialStateValue}
  }

  /* 
  function to update page size
  */
  updateCouponPageSize =(value:number,isRefreshPage:boolean = false)=>{
    this.couponPaginationState.PagingDetails.PageSize = value;
    this.couponPaginationState.PagingDetails.isPagerChange = true;
    if(isRefreshPage)
    {
      this.couponPaginationState.PagingDetails.isRefreshPage = true;
    }
  }

/* 
function to update current page number
*/
  updateCouponPageNumber =(value:number,isRefreshPage:boolean = false)=>{
    this.couponPaginationState.PagingDetails.PageNo = value;
    this.couponPaginationState.PagingDetails.isPagerChange = true;
    if(isRefreshPage)
    {
      this.couponPaginationState.PagingDetails.isRefreshPage = true;
    }
  }
  resetCouponPagingState = ()=>{
      this.couponPaginationState.PagingDetails.isPagerChange = false;
      this.couponPaginationState.PagingDetails.isRefreshPage = false;
  }
  /*
    Function to create coupon.
  */
  createCoupon = (coupon: ICouponInfo) => {
    this.addUpdateCouponState.inProgress = true;
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const url = URLConstants.createCoupon;
    const data = {
      CouponName: coupon.couponName,
      Status: coupon.status,
      CouponCode: coupon.couponCode,
      Discount: coupon.discount,
      CouponCount:coupon.CouponCount,
      ValidUpto: new Date(new Date(coupon.ValidUpto).getTime() - tzoffset).toISOString(),
    }
    return baseService.postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<ICouponInfo>>) => {
        this.couponDataList.CouponList.push(response.data.Data);
        this.addUpdateCouponState.success = true;
        toast.success(formatMessage("added_success"));
      })
      .catch((err: string) => {
        // console.log(err);
        this.addUpdateCouponState.error = err;
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateCouponState.inProgress = false;
        })
      );
  }


  /*
    Function to update the coupons
  */
  updateCoupon = (coupon: ICouponInfo) => {
    this.addUpdateCouponState.inProgress = true;
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var url = URLConstants.UpdateCoupon;

    const data = {
      id: coupon.id,
      couponName: coupon.couponName,
      status: coupon.status,
      couponCode: coupon.couponCode,
      discount: coupon.discount,
      CouponCount:coupon.CouponCount,
      ValidUpto: new Date(new Date(coupon.ValidUpto).getTime() - tzoffset).toISOString(),
    }

    return baseService.putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addUpdateCouponState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.addUpdateCouponState.success = true;
          toast.success(formatMessage("updated_success"));
        }
      })
      .catch((err: string) => {
        // console.log(err);
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addUpdateCouponState.inProgress = false;
        })
      );
  };

  /*
    Function to delte the event.
  */
  deleteCoupon = (couponId: number) => {
    this.deleteCouponState.inProgress = true;
    const url = URLConstants.DeleteCoupon + "?couponId=" + couponId;
    return baseService
      .deleteRequest(url)
      .then((responseData: any) => {
        if (responseData.status === 200) {
          this.deleteCouponState.success = true;
          toast.success(formatMessage("deleted_success"));
        }
      })
      .catch((err: string) => {
        // console.log(err);
        this.deleteCouponState.error = err;
        toast.error(formatMessage(err));
        // return null;
      })
      .finally(
        action(() => {
          this.deleteCouponState.inProgress = false;
        })
      );
  }


  /*
    Function to get all the payments
  */
  getAllPayments = (currentPage:number,pagerSize:number, searchText: string) => {
    var url = URLConstants.GetAllPaymentList  + '?PageNo=' + currentPage + '&PageSize=' + pagerSize;
    if (searchText != null) {
      url += "&textToBeSearched=" + searchText;
    }
    if(this.paymentFilterState.monthToBeFiltered != null){
      url += "&monthToBeFiltered=" + this.paymentFilterState.monthToBeFiltered;
    }
    if(this.paymentFilterState.yearToBeFiltered != null){
      url += "&yearToBeFiltered=" + this.paymentFilterState.yearToBeFiltered;
    }
    this.paymentDataList = GetAllPaymentsInitialState;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IGetAllPayments>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.paymentDataList = response.data.Data;
          this.paymentPaginationState.PagingDetails = response.data.Data.PagingDetails;
        }
      })
      .catch((err: string) => {
        // console.log(err);
        toast.error(formatMessage(err));
      })
  };

  /* 
  function to update page size
  */
  updatePaymentPageSize =(value:number,isRefreshPage:boolean = false)=>{
    this.paymentPaginationState.PagingDetails.PageSize = value;
    this.paymentPaginationState.PagingDetails.isPagerChange = true;
    if(isRefreshPage)
    {
      this.paymentPaginationState.PagingDetails.isRefreshPage = true;
    }
  }

/* 
function to update current page number
*/
  updatePaymentPageNumber =(value:number,isRefreshPage:boolean = false)=>{
    this.paymentPaginationState.PagingDetails.PageNo = value;
    this.paymentPaginationState.PagingDetails.isPagerChange = true;
    if(isRefreshPage)
    {
      this.paymentPaginationState.PagingDetails.isRefreshPage = true;
    }
  }
  resetPaymentPagingState = ()=>{
      this.paymentPaginationState.PagingDetails.isPagerChange = false;
      this.paymentPaginationState.PagingDetails.isRefreshPage = false;
  }

  updatePaymentSearchingText = (value: string) => {
    this.paymentFilterState.userNameToBeSearched = value;
  }
  updatePaymentMonthFilteredText = (value: string) => {
    this.paymentFilterState.monthToBeFiltered = value;
  }
  updatePaymentYearFilteredText = (value: string) => {
    this.paymentFilterState.yearToBeFiltered = value;
  }

  resetPaymentFilter = () => {
    this.paymentFilterState = {...this.initialPaymentFilterStateValue}
  }

  /* ************************** Threshold ************************** */

/*
  Function to get all the threshold
*/
  getAllThreshold = () => {
    this.getAllThresholdState.inProgress = true;
    var url = URLConstants.GetAllThreshold;
    this.thresholdList = GetAllThresholdInitialState;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IGetAllThreshold>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          this.getAllThresholdState.error = formatMessage(response.data.Message);
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.getAllThresholdState.success = true;
          this.thresholdList = response.data.Data;
        }
      })
      .catch((err: string) => {
        // console.log(err);
        this.getAllThresholdState.error = formatMessage(err);
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.getAllThresholdState.inProgress = false;
        })
      );
  };


  /*
 Function to create threshold.
*/
  createThreshold = (threshold: IAddThreshold) => {
    this.addupdateThresholdState.inProgress = true;
    const url = URLConstants.createThreshold;
    const data = {
      Key: `custom${this.thresholdList.Threshold.length + 1}`,
      Label: threshold.Label,
      Slug: threshold.Slug,
      Value: threshold.Value,
      IsOriginal: threshold.IsOriginal
    }
    return baseService.postRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<IThresholdInfo>>) => {
        this.thresholdList.Threshold.push(response.data.Data);
        this.addupdateThresholdState.success = true;
        toast.success(formatMessage("added_success"));
      })
      .catch((err: string) => {
        // console.log(err);
        this.addupdateThresholdState.error = err;
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addupdateThresholdState.inProgress = false;
        })
      );
  }


  /*
    Function to update the threshold
  */
  updateThreshold = (threshold: IThreshold) => {
    this.addupdateThresholdState.inProgress = true;
    var url = URLConstants.UpdateThreshold;

    const data = {
      ThresholdId: threshold.ThresholdId,
      Key: threshold.Key,
      Label: threshold.Label,
      Slug: threshold.Slug,
      Value: threshold.Value,
      IsOriginal: threshold.IsOriginal
    }

    return baseService.putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.addupdateThresholdState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.addupdateThresholdState.success = true;
          toast.success(formatMessage("updated_success"));
        }
      })
      .catch((err: string) => {
        // console.log(err);
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.addupdateThresholdState.inProgress = false;
        })
      );
  };


  /*
  Function to delete the threshold
*/
  deleteThreshold = (ThresholdId: number) => {
    this.deleteThresholdState.inProgress = true;
    const url = URLConstants.DeleteThreshold + "?ThresholdId=" + ThresholdId;
    return baseService
      .deleteRequest(url)
      .then((responseData: any) => {
        if (responseData.status === 200) {
          this.deleteThresholdState.success = true;
          toast.success(formatMessage("deleted_success"));
          // console.log(responseData);
        }
      })
      .catch((err: string) => {
        // console.log(err);
        this.deleteThresholdState.error = err;
        toast.error(formatMessage(err));
        return null;
      })
      .finally(
        action(() => {
          this.deleteThresholdState.inProgress = false;
        })
      );
  }

  /*
    Function to reset the state
  */
  resetDeleteThresholdState = () => {
    this.deleteThresholdState = { ...this.initialStateValue }
  }

  resetAddUpdateThresholdState = () => {
    this.addupdateThresholdState = { ...this.initialStateValue }
  }


  /* ************************** Test ************************** */

  /*
    Function to get all the test
  */
  getAllTest = (currentPage: number, pagerSize: number, filterTestTypeList: string | null, RolefilterList: string | null, searchingText: string, trainerList: string) => {
    this.getAllTestState.inProgress = true;

    var url = URLConstants.GetAllTest + '?PageNo=' + currentPage + '&PageSize=' + pagerSize;
    if (filterTestTypeList != null && filterTestTypeList.length > 0) {
      url += "&testType=" + filterTestTypeList;

    }
    if (RolefilterList != null && RolefilterList.length > 0) {
      url += "&roles=" + RolefilterList;

    }
    if (searchingText != null && searchingText != "") {
      url += "&textToBeSearched=" + searchingText;

    }
    if (trainerList != null && trainerList != "") {
      url += "&trainerList=" + trainerList;

    }
    this.testDataList = GetAllTestInitialState;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<IApiSuccessResponse<IGetAllTest>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          this.getAllTestState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.testDataList = response.data.Data;
          this.testPaginationState.PagingDetails = response.data.Data.PagingDetails;
          this.getAllTestState.success = true;
        }
      })
      .catch((err: string) => {
        // console.log(err);
        toast.error(formatMessage(err));
        this.getAllTestState.error = err;
      })
      .finally(action(() => {
          this.getAllTestState.inProgress = false;
      }));

  }

  resetGetAllTestState = () => {
      this.getAllTestState ={...this.initialStateValue};
  }

    /* 
  function to update current page size
  */
  updateTestPageSize =(value:number,isRefreshPage:boolean = false)=>{
    this.testPaginationState.PagingDetails.PageSize = value;
    this.testPaginationState.PagingDetails.isPagerChange = true;
    if(isRefreshPage)
    {
      this.testPaginationState.PagingDetails.isRefreshPage = true;
    }
  }

 /* 
 function to update current page number
 */
  updateTestPageNumber =(value:number,isRefreshPage:boolean = false)=>{
    this.testPaginationState.PagingDetails.PageNo = value;
    this.testPaginationState.PagingDetails.isPagerChange = true;
    if(isRefreshPage)
    {
      this.testPaginationState.PagingDetails.isRefreshPage = true;
    }
   }
   resetTestPagingState = ()=>{
    this.testPaginationState.PagingDetails.isPagerChange = false;
    this.testPaginationState.PagingDetails.isRefreshPage = false;
}

  /*
    Function to delete the test
  */
  deleteTest = (testId: number) => {
    this.deleteTestState.inProgress = true;
    const url = URLConstants.DeleteTest + "?testId=" + testId;
    return baseService
      .deleteRequest(url)
      .then((responseData: any) => {
        if (responseData.status === 200) {
          this.deleteTestState.success = true;
          toast.success(formatMessage('deleted_success'));
          // console.log(responseData);
        }
      })
      .catch((err: string) => {
        // console.log(err);
        this.deleteTestState.error = err;
        toast.error(formatMessage(err));
        return null;
      })
      .finally(
        action(() => {
          this.deleteTestState.inProgress = false;
        })
      );
  }

  /*
    Function to reset the deleteTestState
  */
  resetDeleteTest = () => {
    this.deleteTestState = { ...this.initialStateValue }
  }

  /*
  Function to reset the Filter of Test
*/
  resetTestFilter = () => {
    this.testFilterState.Roles = "";
    this.testFilterState.TestType = "";
    this.testFilterState.trainerList = "";
    this.testFilterState.SearchText = "";
    // this.testFilterState = { ...this.initialRolesAndTestTypeFilterStateValue }
    // this.testFilterStateValue = {...this.initialTestFilterStateValue}
    this.testFilterStateValue.testFilterList.map((item: ITestFilter) => {
      item.value.map((data: IFilterTest) => {
            data.checked = false;
      });
    })
  }

  updateTestSearchingText = (value: string) => {
    this.testFilterState.SearchText = value;
  }

  /* ************************** Stats ************************** */
  resetStatsFilter = () => {
    this.statsDataList = GetAllStatsInitialState;
    this.getStatState = { ...this.initialStateValue }
  }

  // Function to convert a comma-separated string to an array of integers
 convertToIntArray = (csvString: string) => {
  if (!csvString) {
    // Return an empty array if the string is null, undefined, or empty
    return [];
  }

  // Split the string by commas, trim spaces, convert to numbers, and filter out NaN values
  return csvString.toString()
    .split(',')
    .map(item => item.trim())           // Trim any extra spaces
    .filter(item => item.length > 0)   // Filter out empty strings
    .map(Number)                        // Convert to numbers
    .filter(num => !isNaN(num));       // Remove any NaN values resulting from invalid conversion
}


  getStatsFilter = (statData: Array<IStatState>) => {
    if (statData.length == 0) {
      return;
    }
    this.getStatState.inProgress = true;
    var url = URLConstants.GetStatsData;

    const dataArray = statData.map(stats => ({
      Nation: stats.Nation,
      Gender: stats.Gender,
      TestType: stats.Type.toUpperCase(),
      //Invited: stats.Invited,
      TestId: this.convertToIntArray(stats.TestIds),
      WorkPositionType: stats.WorkPositionType,
      JobSectorType: stats.JobSectorTpye,
      AgeRange: stats.AgeRange
      //DOB1: stats.DOB1,
      //DOB2: stats.DOB2
    }));
    this.statsDataList = GetAllStatsInitialState;
    return baseService
      .postRequest(url, { StatsDataList: dataArray })
      .then((response: IApiResponse<IApiSuccessResponse<string>>) => {
        if (response.data.Error) {
          this.error = response.data.Message;
          this.getStatState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          const responseData = JSON.parse(response.data.Data);
          this.statsDataList = JSON.parse(response.data.Data);
          this.getStatState.success = true;
        }
      })
      .catch((err: string) => {
        // console.log(err);
        this.getStatState.error = err;
        toast.error(formatMessage(err));
      })
      .finally(action(() => {
        this.getStatState.inProgress = false;
      }));
  }

  
  get getFigures(): IFigures{
    const figures = {
      Id: this.FiguresList.Id,
      TestTakenCount : this.FiguresList.TestTakenCount,
      CountriesCount : this.FiguresList.CountriesCount,
      CompaniesCount : this.FiguresList.CompaniesCount,
      CoachesCount : this.FiguresList.CoachesCount,
      TrainersCount : this.FiguresList.TrainersCount
    }
    return figures;
  }

  /* API to get the all figures count */

  getFiguresCount = ()=>{
    this.getFiguresCountState.inProgress = true;
    var url = URLConstants.GetFiguresCount;
    return baseService
    .getRequest(url)
    .then((response:IApiResponse<IApiSuccessResponse<IFigures>>)=>{
      if(response.data.Error){
        this.error = response.data.Message;
        toast.error(formatMessage(response.data.Message));
        this.getFiguresCountState.error = response.data.Message;
      }
      else{
        this.FiguresList = response.data.Data;
        this.getFiguresCountState.success = true;
      }
    })
    .catch((err: string) => {
      toast.error(formatMessage(err));
      this.getFiguresCountState.error = err;
    })
    .finally(
      action(() => {
        this.getFiguresCountState.inProgress = false;
      })
    );
  }

  get allFigures():IFigures{
    const figures = toJS(this.FiguresList);
    return {
      Id: figures.Id,
      TestTakenCount : figures.TestTakenCount,
      CountriesCount : figures.CountriesCount,
      CompaniesCount : figures.CompaniesCount,
      CoachesCount : figures.CoachesCount,
      TrainersCount : figures.TrainersCount
    }
  }

  updateFigures = (figures: IFigures) => {
    this.updateFiguresState.inProgress = true;
    var url = URLConstants.UpdateFigures;

    const data = {
      "Figures":
      {
         Id:1,
         TestTakenCount : figures.TestTakenCount,
         CountriesCount : figures.CountriesCount,
         CompaniesCount : figures.CompaniesCount,
         CoachesCount : figures.CoachesCount,
         TrainersCount : figures.TrainersCount
    }}

    return baseService.putRequest(url, data)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.updateFiguresState.error = response.data.Message;
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.updateFiguresState.success = true;
          toast.success(formatMessage("updated_success"));
        }
      })
      .catch((err: string) => {
        // console.log(err);
        toast.error(formatMessage(err));
      })
      .finally(
        action(() => {
          this.updateFiguresState.inProgress = false;
        })
      );
  };

  /*
    Function to fetch the figures from the backend
  */
  getCreatorsInfo = () => {
    this.getCreatorInfoState.inProgress = true;
    const url = URLConstants.GetCreatorInfo;
    return baseService.getRequest(url)
    .then((response: IApiResponse<IApiSuccessResponse<ICreator[]>>) => {
      if (response.data.Error) {
        this.getCreatorInfoState.error = response.data.Message;
        // console.log(response.data.Data);
        // toast.error(formatMessage(response.data.Message));
      }
      else {
        this.getCreatorInfoState.success = true;
        this.creatorList = response.data.Data;
        // console.log(response.data.Data);
        // toast.success(formatMessage("updated_success"));
      }
    })
    .catch((err: string) => {
      // console.log(err);
      toast.error(formatMessage(err));
      this.getCreatorInfoState.error = formatMessage(err);
    })
    .finally(
      action(() => {
        this.getCreatorInfoState.inProgress = false;
      })
    );
  }

  /*
    Computed Function to fetch all the creators.
  */
  get allCreators(): ICreator[] {
    if (this.creatorList && this.creatorList.length > 0) {
      return this.creatorList.map((creator: ICreator) => {
        return {
          Id: creator.Id,
          CreatorName: creator.CreatorName,
          CreatorDesc_EN: creator.CreatorDesc_EN,
          CreatorDesc_JP: creator.CreatorDesc_JP,
          CreatorDesc_IT: creator.CreatorDesc_IT,
          LinkedInURL: creator.LinkedInURL,
          MailId: creator.MailId,
          CreatorImageInfo: creator.CreatorImageInfo
        }
      })
    }
    return [];
  }

  /*
    Function to update creator info.
  */
  updateCreatorInfo = (creatorInfo: ICreator) => {
    this.updateCreatorInfoState.inProgress = true;
    const url = URLConstants.UpdateCreatorInfo;
    return baseService.putRequest(url, creatorInfo)
    .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
      if (response.data.Error) {
        this.updateCreatorInfoState.error = response.data.Message;
        // console.log(response.data.Data);
        // toast.error(formatMessage(response.data.Message));
      }
      else {
        this.updateCreatorInfoState.success = true;
        // this.creatorList = response.data.Data;
        // console.log(response.data.Data);
        toast.success(formatMessage("updated_success"));
      }
    })
    .catch((err: string) => {
      // console.log(err);
      toast.error(formatMessage(err));
      this.updateCreatorInfoState.error = formatMessage(err);
    })
    .finally(
      action(() => {
        this.updateCreatorInfoState.inProgress = false;
      })
    );

  }

  /*
    Function to update creator info.
  */
  uploadCreatorImage = (id: number, file: File | undefined) => {
    if (file) {
      let fileData = new FormData();
      
      // [Arjun]: Please do not change this "imageFile" key below. This needs to be the same as the API parameter name.
      fileData.append("imageFile", file);
      
      this.uploadCreatorImageState.inProgress = true;
      const url = `${URLConstants.UploadCreatorImageData}?id=${id}`;

      return baseService.postUpload(url, fileData)
      .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
        if (response.data.Error) {
          this.uploadCreatorImageState.error = response.data.Message;
          // console.log(response.data.Data);
          toast.error(formatMessage(response.data.Message));
        }
        else {
          this.uploadCreatorImageState.success = true;
          // this.creatorList = response.data.Data;
          // console.log(response.data.Data);
          toast.success(formatMessage("updated_success"));
        }
      })
      .catch((err: string) => {
        // console.log(err);
        toast.error(formatMessage(err));
        this.uploadCreatorImageState.error = formatMessage(err);
      })
      .finally(
        action(() => {
          this.uploadCreatorImageState.inProgress = false;
        })
      );
    }
    return;
  }

  /*
    Computed Function to fetch all the users.
  */
  get allUsers(): IUser[] {
    if (this.usersDataList?.Users && this.usersDataList.Users?.length > 0) {
      //Sorting according to CreatedDate.
      return this.usersDataList.Users.map((user: IUser) => {
        return {
          Id: user.Id,
          Email: user.Email,
          FirstName: user.FirstName,
          LastName: user.LastName,
          UserType: user.UserType,
          DateOfBirth: user.DateOfBirth,
          Gender: user.Gender,
          Nation: user.Nation,
          CountryOfResidence: user.CountryOfResidence,
          CreatedDate: user.CreatedDate,
          Education: user.Education,
          WorkPosition: user.WorkPosition,
          JobSector: user.JobSector,
          IsAgent: user.IsAgent,
          SubscriptionExpiryDate: user.SubscriptionExpiryDate,
          TrainerInvitesAvailable: user.TrainerInvitesAvailable,
          Referent: user.Referent,
          AnyPaymentDone: user.AnyPaymentDone
        };
      });
    }
    return [];
  }

  /*
   Computed Function to get the user detail when we open the edit user dialog.
  */
  get getSelectedUserDetails(): any {
    if (this.selectedUser) {
      return {
        Email: this.selectedUser.Email,
        FirstName: this.selectedUser.FirstName,
        LastName: this.selectedUser.LastName,
        UserType: this.getUserTypeInNumber(this.selectedUser.UserType),
        SubscriptionExpiryDate: this.selectedUser.SubscriptionExpiryDate ? new Date(this.selectedUser.SubscriptionExpiryDate) : new Date(Date.now()),
        TrainerInvitesAvailable: this.selectedUser.TrainerInvitesAvailable,
        IsAgent: this.selectedUser.IsAgent ? this.selectedUser.IsAgent : false,
      };
    }
    return AddUpdateUserInitialState
  }

  /*
    Computed Function to fetch all the labels.
  */
  get allLabel(): ILabel[] {
    const labels = toJS(this.labelDataList?.LabelsList);
    if (labels && labels?.length > 0) {
      const allLabelSortedData = toJS(this.labelDataList?.LabelsList);
      return allLabelSortedData.map((label: ILabel) => {
        return {
          id: label.id,
          it: label.it,
          en: label.en,
          jp: label.jp,
        };
      });
    }
    return [];
  }

  /*
    Computed Function to fetch all the coupons.
  */
  get allCoupons(): ICoupon[] {
    const coupons = toJS(this.couponDataList?.CouponList);
    if (coupons && coupons?.length > 0) {
      // const allCouponSortedData = toJS(this.couponDataList?.CouponList).sort((a: any, b: any) => Number(a.CreatedDate) - Number(b.CreatedDate));
      return this.couponDataList?.CouponList.map((coupon: ICoupon) => {
        return {
          id: coupon.id,
          couponName: coupon.couponName,
          couponCode: coupon.couponCode,
          discount: coupon.discount,
          status: coupon.status,
          CouponCount: coupon.CouponCount,
          ValidUpto: coupon.ValidUpto
        };
      });
    }
    return [];
  }

  /*
    Computed Function to fetch all the coupons.
  */
  get allPayments(): IPaymentInfo[] {
    const payments = toJS(this.paymentDataList?.PaymentList);
    if (payments && payments?.length > 0) {
      // const allPaymentsSortedData = toJS(this.paymentDataList?.PaymentList).sort((a: any, b: any) => Number(a.PaymentDate) - Number(b.PaymentDate));
      return this.paymentDataList?.PaymentList.map((payment: IPaymentInfo) => {
        return {
          Email: payment.Email,
          FirstName: payment.FirstName,
          LastName: payment.LastName,
          PaymentDate: this.getNormalizedDateTime(payment.PaymentDate),
          ProductName: payment.ProductName,
          ProductNameStr: payment.ProductNameStr,
          Amount: payment.Amount,
          CouponCode: payment.CouponCode,
        };
      });
    }
    return [];
  }

  /*
    Computed Function to fetch all the coupons.
  */
  get allThreshold(): IThreshold[] {
    const thresholdValues = toJS(this.thresholdList?.Threshold);
    if (thresholdValues && thresholdValues?.length > 0) {
      const allThresholdData = toJS(this.thresholdList?.Threshold).sort((a, b) => Number(a.ThresholdId) - Number(b.ThresholdId));
      return allThresholdData.map((threshold: IThreshold) => {
        return {
          ThresholdId: threshold.ThresholdId,
          Key: threshold.Key,
          Label: threshold.Label,
          Slug: threshold.Slug,
          Value: threshold.Value,
          IsOriginal: threshold.IsOriginal
        };
      });
    }
    return [];
  }

  /*
    Computed Function to get the coupon detail when we open the edit coupon dialog.
  */
  get getCouponDetail(): IAddCoupn {
    if (this.couponList)
      return {
        id: this.couponList.id,
        couponName: this.couponList.couponName,
        couponCode: this.couponList.couponCode,
        discount: this.couponList.discount,
        status: this.couponList.status,
        CouponCount: this.couponList.CouponCount,
        ValidUpto: this.couponList.ValidUpto
      };
    return CouponState
  }


  /*
    Computed Function to fetch all the test.
  */
  get allTest(): ITestInfo[] {
    const test = toJS(this.testDataList?.TestList);
    if (test && test?.length > 0) {
      // const allTestSortedData = toJS(this.testDataList?.TestList).sort((a: any, b: any) => Number(a.TestDate) - Number(b.TestDate));
      return this.testDataList?.TestList.map((test: ITestInfo) => {
        return {
          // FirstName: test.FirstName,
          // LastName: test.LastName,
          TestId: test.TestId,
          UserEmail: test.UserEmail,
          TestDate: this.getNormalisedDate(test.TestDate),
          TestType: test.TestType,
          TrainerId: test.TrainerId,
          IsPaidTest: test.IsPaidTest
        };
      });
    }
    return [];
  }

  get allstatsData(): IGetAllStatsData {
    const statData = toJS(this.statsDataList.CategoryList);
    if (statData && statData?.length > 0) {
      const allStatSortedData = toJS(this.statsDataList?.CategoryList);
      let categoryList = allStatSortedData.map((category: ICategoryInfo) => {
        return {
          CategoryKey: category.CategoryKey,
          SubCategoryList: category.SubCategoryList
        };
      })
      const allStatFilteredData = toJS(this.statsDataList?.FilterInfoList);
      let numberOfTest = allStatFilteredData.map((filterInfo: IFilterInfo) => {
        return {
          NumberOfTest: filterInfo.NumberOfTest,
          FilterId: filterInfo.FilterId
        }
      })
      return {
        CategoryList: categoryList,
        FilterInfoList: numberOfTest,
      };
    }
    return GetAllStatsInitialState;
  }

  /*
    Helper Function which converts unixTimestamp to Date in the format (dd/MM/yyyy, hh:mm:ss)
  */
  getNormalisedDate = (unixTimestamp: string) => {
    if (Number.parseInt(unixTimestamp)) {
      var date = new Date(Number(unixTimestamp) * 1000);
      const formattedDate = date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      const formattedTime = date.toLocaleTimeString("en-GB", {
        hour12: false, // Use 24-hour format
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });

      return `${formattedDate} ${formattedTime}`;
    }
    return "NaN";
  }

  getNormalizedDateTime = (dateString: string) => {
    if (Date.parse(dateString)) {
      var date = new Date(dateString);
      const formattedDate = date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      const formattedTime = date.toLocaleTimeString("en-GB", {
        hour12: false, // Use 24-hour format
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
      return `${formattedDate} ${formattedTime}`;
    }
    return "NaN";
  }

  /*
    Helper Function which converts unixTimestamp to Date in the format (dd/MM/yyyy, hh:mm:ss)
  */
  getUserTypeInNumber = (userType: string) => {
    switch (userType) {
      case "Admin":
        return UserTypeNumber.Admin;
      case "Personal":
        return UserTypeNumber.Personal;
      case "Trainer":
        return UserTypeNumber.Trainer;
      default:
        return UserTypeNumber.Personal;
    }
  }
}
export default new AdminStore();
